/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.

export type CreateTenantInput = {
  id?: string | null,
  name: string,
  referenceIdentifier: string,
  _version?: number | null,
  tenantAdminId: string,
};

export type ModelTenantConditionInput = {
  name?: ModelStringInput | null,
  referenceIdentifier?: ModelStringInput | null,
  and?: Array< ModelTenantConditionInput | null > | null,
  or?: Array< ModelTenantConditionInput | null > | null,
  not?: ModelTenantConditionInput | null,
  _deleted?: ModelBooleanInput | null,
  tenantAdminId?: ModelIDInput | null,
};

export type ModelStringInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export enum ModelAttributeTypes {
  binary = "binary",
  binarySet = "binarySet",
  bool = "bool",
  list = "list",
  map = "map",
  number = "number",
  numberSet = "numberSet",
  string = "string",
  stringSet = "stringSet",
  _null = "_null",
}


export type ModelSizeInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
};

export type ModelBooleanInput = {
  ne?: boolean | null,
  eq?: boolean | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type ModelIDInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export type Tenant = {
  __typename: "Tenant",
  id: string,
  name: string,
  referenceIdentifier: string,
  admin: User,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
  tenantAdminId: string,
};

export type User = {
  __typename: "User",
  id: string,
  username: string,
  cognitoId: string,
  tenantId: string,
  isPrimaryAdmin?: number | null,
  contact?: AddressAndContactDetails | null,
  displayName: string,
  name?: string | null,
  roles: Array< Role >,
  dateOfBirth?: string | null,
  riderResponsibility?: string | null,
  possibleRiderResponsibilities?: ModelPossibleRiderResponsibilitiesConnection | null,
  profilePicture?: S3Object | null,
  comments?: ModelCommentConnection | null,
  assignments?: ModelTaskAssigneeConnection | null,
  vehicleAssignments?: ModelVehicleAssignmentConnection | null,
  createdTasks?: ModelTaskConnection | null,
  createdLocations?: ModelLocationConnection | null,
  createdVehicles?: ModelVehicleConnection | null,
  createdScheduledTasks?: ModelScheduledTaskConnection | null,
  disabled?: number | null,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type AddressAndContactDetails = {
  __typename: "AddressAndContactDetails",
  name?: string | null,
  telephoneNumber?: string | null,
  mobileNumber?: string | null,
  emailAddress?: string | null,
  ward?: string | null,
  line1?: string | null,
  line2?: string | null,
  line3?: string | null,
  town?: string | null,
  county?: string | null,
  state?: string | null,
  country?: string | null,
  postcode?: string | null,
  what3words?: string | null,
};

export enum Role {
  USER = "USER",
  COORDINATOR = "COORDINATOR",
  RIDER = "RIDER",
  ADMIN = "ADMIN",
}


export type ModelPossibleRiderResponsibilitiesConnection = {
  __typename: "ModelPossibleRiderResponsibilitiesConnection",
  items:  Array<PossibleRiderResponsibilities | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type PossibleRiderResponsibilities = {
  __typename: "PossibleRiderResponsibilities",
  id: string,
  tenantId: string,
  user: User,
  riderResponsibility: RiderResponsibility,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
  userPossibleRiderResponsibilitiesId?: string | null,
  riderResponsibilityPossibleUsersId?: string | null,
};

export type RiderResponsibility = {
  __typename: "RiderResponsibility",
  id: string,
  tenantId: string,
  label: string,
  disabled?: number | null,
  possibleUsers?: ModelPossibleRiderResponsibilitiesConnection | null,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type S3Object = {
  __typename: "S3Object",
  bucket: string,
  key: string,
  region: string,
};

export type ModelCommentConnection = {
  __typename: "ModelCommentConnection",
  items:  Array<Comment | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type Comment = {
  __typename: "Comment",
  id: string,
  parentId?: string | null,
  owner?: string | null,
  tenantId: string,
  body?: string | null,
  author?: User | null,
  visibility?: CommentVisibility | null,
  archived?: number | null,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
  userCommentsId?: string | null,
};

export enum CommentVisibility {
  EVERYONE = "EVERYONE",
  ME = "ME",
}


export type ModelTaskAssigneeConnection = {
  __typename: "ModelTaskAssigneeConnection",
  items:  Array<TaskAssignee | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type TaskAssignee = {
  __typename: "TaskAssignee",
  id: string,
  tenantId: string,
  role: Role,
  task: Task,
  assignee: User,
  archived?: number | null,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
  userAssignmentsId?: string | null,
  taskAssigneesId?: string | null,
};

export type Task = {
  __typename: "Task",
  id: string,
  tenantId: string,
  createdAt?: string | null,
  createdBy?: User | null,
  dateCreated: string,
  dateCompleted?: string | null,
  timeOfCall?: string | null,
  timePickedUp?: string | null,
  timePickedUpSenderName?: string | null,
  timeDroppedOff?: string | null,
  timeDroppedOffRecipientName?: string | null,
  timeCancelled?: string | null,
  timeRejected?: string | null,
  timeRiderHome?: string | null,
  requesterContact?: AddressAndContactDetails | null,
  pickUpLocationId?: string | null,
  dropOffLocationId?: string | null,
  establishmentLocationId?: string | null,
  pickUpLocation?: Location | null,
  dropOffLocation?: Location | null,
  establishmentLocation?: Location | null,
  riderResponsibility?: string | null,
  assignees?: ModelTaskAssigneeConnection | null,
  priority?: Priority | null,
  deliverables?: ModelDeliverableConnection | null,
  comments?: ModelCommentConnection | null,
  status?: TaskStatus | null,
  isRiderUsingOwnVehicle?: number | null,
  archived?: number | null,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
  userCreatedTasksId?: string | null,
};

export type Location = {
  __typename: "Location",
  id: string,
  tenantId: string,
  createdBy?: User | null,
  name?: string | null,
  listed?: number | null,
  contact?: AddressAndContactDetails | null,
  ward?: string | null,
  line1?: string | null,
  line2?: string | null,
  line3?: string | null,
  town?: string | null,
  county?: string | null,
  state?: string | null,
  country?: string | null,
  postcode?: string | null,
  what3words?: string | null,
  tasksAsPickUp?: ModelTaskConnection | null,
  tasksAsDropOff?: ModelTaskConnection | null,
  taskAsEstablishment?: ModelTaskConnection | null,
  scheduledTasksAsPickUp?: ModelScheduledTaskConnection | null,
  scheduledTasksAsDropOff?: ModelScheduledTaskConnection | null,
  scheduledTasksAsEstablishment?: ModelScheduledTaskConnection | null,
  comments?: ModelCommentConnection | null,
  disabled?: number | null,
  googleMapsPlaceId?: string | null,
  archived?: number | null,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
  userCreatedLocationsId?: string | null,
};

export type ModelTaskConnection = {
  __typename: "ModelTaskConnection",
  items:  Array<Task | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelScheduledTaskConnection = {
  __typename: "ModelScheduledTaskConnection",
  items:  Array<ScheduledTask | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ScheduledTask = {
  __typename: "ScheduledTask",
  id: string,
  tenantId: string,
  createdBy?: User | null,
  requesterContact?: AddressAndContactDetails | null,
  cronExpression: string,
  pickUpLocationId?: string | null,
  dropOffLocationId?: string | null,
  establishmentLocationId?: string | null,
  pickUpLocation?: Location | null,
  dropOffLocation?: Location | null,
  establishmentLocation?: Location | null,
  priority?: Priority | null,
  deliverables?: ModelDeliverableConnection | null,
  disabled?: number | null,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
  userCreatedScheduledTasksId?: string | null,
};

export enum Priority {
  HIGH = "HIGH",
  MEDIUM = "MEDIUM",
  LOW = "LOW",
}


export type ModelDeliverableConnection = {
  __typename: "ModelDeliverableConnection",
  items:  Array<Deliverable | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type Deliverable = {
  __typename: "Deliverable",
  id: string,
  tenantId: string,
  deliverableType?: DeliverableType | null,
  task?: Task | null,
  scheduledTask?: ScheduledTask | null,
  count?: number | null,
  unit?: DeliverableUnit | null,
  orderInGrid?: number | null,
  comments?: ModelCommentConnection | null,
  archived?: number | null,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
  taskDeliverablesId?: string | null,
  scheduledTaskDeliverablesId?: string | null,
  deliverableTypeDeliverablesId?: string | null,
};

export type DeliverableType = {
  __typename: "DeliverableType",
  id: string,
  label: string,
  tenantId: string,
  icon?: DeliverableTypeIcon | null,
  defaultUnit?: DeliverableUnit | null,
  deliverables?: ModelDeliverableConnection | null,
  tags?: Array< string | null > | null,
  disabled?: number | null,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export enum DeliverableTypeIcon {
  BUG = "BUG",
  CHILD = "CHILD",
  DOCUMENT = "DOCUMENT",
  EQUIPMENT = "EQUIPMENT",
  OTHER = "OTHER",
}


export enum DeliverableUnit {
  NONE = "NONE",
  LITER = "LITER",
  MILLILITER = "MILLILITER",
  GRAM = "GRAM",
  ITEM = "ITEM",
  BOX = "BOX",
}


export enum TaskStatus {
  NEW = "NEW",
  ACTIVE = "ACTIVE",
  PICKED_UP = "PICKED_UP",
  DROPPED_OFF = "DROPPED_OFF",
  CANCELLED = "CANCELLED",
  REJECTED = "REJECTED",
  ABANDONED = "ABANDONED",
  COMPLETED = "COMPLETED",
  PENDING = "PENDING",
}


export type ModelVehicleAssignmentConnection = {
  __typename: "ModelVehicleAssignmentConnection",
  items:  Array<VehicleAssignment | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type VehicleAssignment = {
  __typename: "VehicleAssignment",
  id: string,
  tenantId: string,
  vehicle: Vehicle,
  assignee: User,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
  userVehicleAssignmentsId?: string | null,
  vehicleAssignmentsId?: string | null,
};

export type Vehicle = {
  __typename: "Vehicle",
  id: string,
  tenantId: string,
  createdBy?: User | null,
  name?: string | null,
  manufacturer?: string | null,
  model?: string | null,
  dateOfManufacture?: string | null,
  dateOfRegistration?: string | null,
  assignments?: ModelVehicleAssignmentConnection | null,
  comments?: ModelCommentConnection | null,
  disabled?: number | null,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
  userCreatedVehiclesId?: string | null,
};

export type ModelLocationConnection = {
  __typename: "ModelLocationConnection",
  items:  Array<Location | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelVehicleConnection = {
  __typename: "ModelVehicleConnection",
  items:  Array<Vehicle | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type UpdateTenantInput = {
  id: string,
  name?: string | null,
  referenceIdentifier?: string | null,
  _version?: number | null,
  tenantAdminId?: string | null,
};

export type DeleteTenantInput = {
  id: string,
  _version?: number | null,
};

export type CreateUserInput = {
  id?: string | null,
  username: string,
  cognitoId: string,
  tenantId: string,
  isPrimaryAdmin?: number | null,
  contact?: AddressAndContactDetailsInput | null,
  displayName: string,
  name?: string | null,
  roles: Array< Role >,
  dateOfBirth?: string | null,
  riderResponsibility?: string | null,
  profilePicture?: S3ObjectInput | null,
  disabled?: number | null,
  _version?: number | null,
};

export type AddressAndContactDetailsInput = {
  name?: string | null,
  telephoneNumber?: string | null,
  mobileNumber?: string | null,
  emailAddress?: string | null,
  ward?: string | null,
  line1?: string | null,
  line2?: string | null,
  line3?: string | null,
  town?: string | null,
  county?: string | null,
  state?: string | null,
  country?: string | null,
  postcode?: string | null,
  what3words?: string | null,
};

export type S3ObjectInput = {
  bucket: string,
  key: string,
  region: string,
};

export type ModelUserConditionInput = {
  username?: ModelStringInput | null,
  cognitoId?: ModelIDInput | null,
  tenantId?: ModelIDInput | null,
  isPrimaryAdmin?: ModelIntInput | null,
  displayName?: ModelStringInput | null,
  name?: ModelStringInput | null,
  roles?: ModelRoleInput | null,
  dateOfBirth?: ModelStringInput | null,
  riderResponsibility?: ModelStringInput | null,
  disabled?: ModelIntInput | null,
  and?: Array< ModelUserConditionInput | null > | null,
  or?: Array< ModelUserConditionInput | null > | null,
  not?: ModelUserConditionInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelIntInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type ModelRoleInput = {
  eq?: Role | null,
  ne?: Role | null,
};

export type UpdateUserInput = {
  id: string,
  username?: string | null,
  cognitoId?: string | null,
  tenantId?: string | null,
  isPrimaryAdmin?: number | null,
  contact?: AddressAndContactDetailsInput | null,
  displayName?: string | null,
  name?: string | null,
  roles?: Array< Role > | null,
  dateOfBirth?: string | null,
  riderResponsibility?: string | null,
  profilePicture?: S3ObjectInput | null,
  disabled?: number | null,
  _version?: number | null,
};

export type DeleteUserInput = {
  id: string,
  _version?: number | null,
};

export type CreatePossibleRiderResponsibilitiesInput = {
  id?: string | null,
  tenantId: string,
  _version?: number | null,
  userPossibleRiderResponsibilitiesId?: string | null,
  riderResponsibilityPossibleUsersId?: string | null,
};

export type ModelPossibleRiderResponsibilitiesConditionInput = {
  tenantId?: ModelIDInput | null,
  and?: Array< ModelPossibleRiderResponsibilitiesConditionInput | null > | null,
  or?: Array< ModelPossibleRiderResponsibilitiesConditionInput | null > | null,
  not?: ModelPossibleRiderResponsibilitiesConditionInput | null,
  _deleted?: ModelBooleanInput | null,
  userPossibleRiderResponsibilitiesId?: ModelIDInput | null,
  riderResponsibilityPossibleUsersId?: ModelIDInput | null,
};

export type UpdatePossibleRiderResponsibilitiesInput = {
  id: string,
  tenantId?: string | null,
  _version?: number | null,
  userPossibleRiderResponsibilitiesId?: string | null,
  riderResponsibilityPossibleUsersId?: string | null,
};

export type DeletePossibleRiderResponsibilitiesInput = {
  id: string,
  _version?: number | null,
};

export type CreateVehicleInput = {
  id?: string | null,
  tenantId: string,
  name?: string | null,
  manufacturer?: string | null,
  model?: string | null,
  dateOfManufacture?: string | null,
  dateOfRegistration?: string | null,
  disabled?: number | null,
  _version?: number | null,
  userCreatedVehiclesId?: string | null,
};

export type ModelVehicleConditionInput = {
  tenantId?: ModelIDInput | null,
  name?: ModelStringInput | null,
  manufacturer?: ModelStringInput | null,
  model?: ModelStringInput | null,
  dateOfManufacture?: ModelStringInput | null,
  dateOfRegistration?: ModelStringInput | null,
  disabled?: ModelIntInput | null,
  and?: Array< ModelVehicleConditionInput | null > | null,
  or?: Array< ModelVehicleConditionInput | null > | null,
  not?: ModelVehicleConditionInput | null,
  _deleted?: ModelBooleanInput | null,
  userCreatedVehiclesId?: ModelIDInput | null,
};

export type UpdateVehicleInput = {
  id: string,
  tenantId?: string | null,
  name?: string | null,
  manufacturer?: string | null,
  model?: string | null,
  dateOfManufacture?: string | null,
  dateOfRegistration?: string | null,
  disabled?: number | null,
  _version?: number | null,
  userCreatedVehiclesId?: string | null,
};

export type DeleteVehicleInput = {
  id: string,
  _version?: number | null,
};

export type CreateVehicleAssignmentInput = {
  id?: string | null,
  tenantId: string,
  _version?: number | null,
  userVehicleAssignmentsId?: string | null,
  vehicleAssignmentsId?: string | null,
};

export type ModelVehicleAssignmentConditionInput = {
  tenantId?: ModelIDInput | null,
  and?: Array< ModelVehicleAssignmentConditionInput | null > | null,
  or?: Array< ModelVehicleAssignmentConditionInput | null > | null,
  not?: ModelVehicleAssignmentConditionInput | null,
  _deleted?: ModelBooleanInput | null,
  userVehicleAssignmentsId?: ModelIDInput | null,
  vehicleAssignmentsId?: ModelIDInput | null,
};

export type UpdateVehicleAssignmentInput = {
  id: string,
  tenantId?: string | null,
  _version?: number | null,
  userVehicleAssignmentsId?: string | null,
  vehicleAssignmentsId?: string | null,
};

export type DeleteVehicleAssignmentInput = {
  id: string,
  _version?: number | null,
};

export type CreateLocationInput = {
  id?: string | null,
  tenantId: string,
  name?: string | null,
  listed?: number | null,
  contact?: AddressAndContactDetailsInput | null,
  ward?: string | null,
  line1?: string | null,
  line2?: string | null,
  line3?: string | null,
  town?: string | null,
  county?: string | null,
  state?: string | null,
  country?: string | null,
  postcode?: string | null,
  what3words?: string | null,
  disabled?: number | null,
  googleMapsPlaceId?: string | null,
  archived?: number | null,
  _version?: number | null,
  userCreatedLocationsId?: string | null,
};

export type ModelLocationConditionInput = {
  tenantId?: ModelIDInput | null,
  name?: ModelStringInput | null,
  listed?: ModelIntInput | null,
  ward?: ModelStringInput | null,
  line1?: ModelStringInput | null,
  line2?: ModelStringInput | null,
  line3?: ModelStringInput | null,
  town?: ModelStringInput | null,
  county?: ModelStringInput | null,
  state?: ModelStringInput | null,
  country?: ModelStringInput | null,
  postcode?: ModelStringInput | null,
  what3words?: ModelStringInput | null,
  disabled?: ModelIntInput | null,
  googleMapsPlaceId?: ModelStringInput | null,
  archived?: ModelIntInput | null,
  and?: Array< ModelLocationConditionInput | null > | null,
  or?: Array< ModelLocationConditionInput | null > | null,
  not?: ModelLocationConditionInput | null,
  _deleted?: ModelBooleanInput | null,
  userCreatedLocationsId?: ModelIDInput | null,
};

export type UpdateLocationInput = {
  id: string,
  tenantId?: string | null,
  name?: string | null,
  listed?: number | null,
  contact?: AddressAndContactDetailsInput | null,
  ward?: string | null,
  line1?: string | null,
  line2?: string | null,
  line3?: string | null,
  town?: string | null,
  county?: string | null,
  state?: string | null,
  country?: string | null,
  postcode?: string | null,
  what3words?: string | null,
  disabled?: number | null,
  googleMapsPlaceId?: string | null,
  archived?: number | null,
  _version?: number | null,
  userCreatedLocationsId?: string | null,
};

export type DeleteLocationInput = {
  id: string,
  _version?: number | null,
};

export type CreateTaskInput = {
  id?: string | null,
  tenantId: string,
  createdAt?: string | null,
  dateCreated: string,
  dateCompleted?: string | null,
  timeOfCall?: string | null,
  timePickedUp?: string | null,
  timePickedUpSenderName?: string | null,
  timeDroppedOff?: string | null,
  timeDroppedOffRecipientName?: string | null,
  timeCancelled?: string | null,
  timeRejected?: string | null,
  timeRiderHome?: string | null,
  requesterContact?: AddressAndContactDetailsInput | null,
  pickUpLocationId?: string | null,
  dropOffLocationId?: string | null,
  establishmentLocationId?: string | null,
  riderResponsibility?: string | null,
  priority?: Priority | null,
  status?: TaskStatus | null,
  isRiderUsingOwnVehicle?: number | null,
  archived?: number | null,
  _version?: number | null,
  userCreatedTasksId?: string | null,
};

export type ModelTaskConditionInput = {
  tenantId?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  dateCreated?: ModelStringInput | null,
  dateCompleted?: ModelStringInput | null,
  timeOfCall?: ModelStringInput | null,
  timePickedUp?: ModelStringInput | null,
  timePickedUpSenderName?: ModelStringInput | null,
  timeDroppedOff?: ModelStringInput | null,
  timeDroppedOffRecipientName?: ModelStringInput | null,
  timeCancelled?: ModelStringInput | null,
  timeRejected?: ModelStringInput | null,
  timeRiderHome?: ModelStringInput | null,
  pickUpLocationId?: ModelIDInput | null,
  dropOffLocationId?: ModelIDInput | null,
  establishmentLocationId?: ModelIDInput | null,
  riderResponsibility?: ModelStringInput | null,
  priority?: ModelPriorityInput | null,
  status?: ModelTaskStatusInput | null,
  isRiderUsingOwnVehicle?: ModelIntInput | null,
  archived?: ModelIntInput | null,
  and?: Array< ModelTaskConditionInput | null > | null,
  or?: Array< ModelTaskConditionInput | null > | null,
  not?: ModelTaskConditionInput | null,
  _deleted?: ModelBooleanInput | null,
  userCreatedTasksId?: ModelIDInput | null,
};

export type ModelPriorityInput = {
  eq?: Priority | null,
  ne?: Priority | null,
};

export type ModelTaskStatusInput = {
  eq?: TaskStatus | null,
  ne?: TaskStatus | null,
};

export type UpdateTaskInput = {
  id: string,
  tenantId?: string | null,
  createdAt?: string | null,
  dateCreated?: string | null,
  dateCompleted?: string | null,
  timeOfCall?: string | null,
  timePickedUp?: string | null,
  timePickedUpSenderName?: string | null,
  timeDroppedOff?: string | null,
  timeDroppedOffRecipientName?: string | null,
  timeCancelled?: string | null,
  timeRejected?: string | null,
  timeRiderHome?: string | null,
  requesterContact?: AddressAndContactDetailsInput | null,
  pickUpLocationId?: string | null,
  dropOffLocationId?: string | null,
  establishmentLocationId?: string | null,
  riderResponsibility?: string | null,
  priority?: Priority | null,
  status?: TaskStatus | null,
  isRiderUsingOwnVehicle?: number | null,
  archived?: number | null,
  _version?: number | null,
  userCreatedTasksId?: string | null,
};

export type DeleteTaskInput = {
  id: string,
  _version?: number | null,
};

export type CreateTaskAssigneeInput = {
  id?: string | null,
  tenantId: string,
  role: Role,
  archived?: number | null,
  _version?: number | null,
  userAssignmentsId?: string | null,
  taskAssigneesId?: string | null,
};

export type ModelTaskAssigneeConditionInput = {
  tenantId?: ModelIDInput | null,
  role?: ModelRoleInput | null,
  archived?: ModelIntInput | null,
  and?: Array< ModelTaskAssigneeConditionInput | null > | null,
  or?: Array< ModelTaskAssigneeConditionInput | null > | null,
  not?: ModelTaskAssigneeConditionInput | null,
  _deleted?: ModelBooleanInput | null,
  userAssignmentsId?: ModelIDInput | null,
  taskAssigneesId?: ModelIDInput | null,
};

export type UpdateTaskAssigneeInput = {
  id: string,
  tenantId?: string | null,
  role?: Role | null,
  archived?: number | null,
  _version?: number | null,
  userAssignmentsId?: string | null,
  taskAssigneesId?: string | null,
};

export type DeleteTaskAssigneeInput = {
  id: string,
  _version?: number | null,
};

export type CreateScheduledTaskInput = {
  id?: string | null,
  tenantId: string,
  requesterContact?: AddressAndContactDetailsInput | null,
  cronExpression: string,
  pickUpLocationId?: string | null,
  dropOffLocationId?: string | null,
  establishmentLocationId?: string | null,
  priority?: Priority | null,
  disabled?: number | null,
  _version?: number | null,
  userCreatedScheduledTasksId?: string | null,
};

export type ModelScheduledTaskConditionInput = {
  tenantId?: ModelIDInput | null,
  cronExpression?: ModelStringInput | null,
  pickUpLocationId?: ModelIDInput | null,
  dropOffLocationId?: ModelIDInput | null,
  establishmentLocationId?: ModelIDInput | null,
  priority?: ModelPriorityInput | null,
  disabled?: ModelIntInput | null,
  and?: Array< ModelScheduledTaskConditionInput | null > | null,
  or?: Array< ModelScheduledTaskConditionInput | null > | null,
  not?: ModelScheduledTaskConditionInput | null,
  _deleted?: ModelBooleanInput | null,
  userCreatedScheduledTasksId?: ModelIDInput | null,
};

export type UpdateScheduledTaskInput = {
  id: string,
  tenantId?: string | null,
  requesterContact?: AddressAndContactDetailsInput | null,
  cronExpression?: string | null,
  pickUpLocationId?: string | null,
  dropOffLocationId?: string | null,
  establishmentLocationId?: string | null,
  priority?: Priority | null,
  disabled?: number | null,
  _version?: number | null,
  userCreatedScheduledTasksId?: string | null,
};

export type DeleteScheduledTaskInput = {
  id: string,
  _version?: number | null,
};

export type CreateCommentInput = {
  id?: string | null,
  parentId?: string | null,
  owner?: string | null,
  tenantId: string,
  body?: string | null,
  visibility?: CommentVisibility | null,
  archived?: number | null,
  _version?: number | null,
  userCommentsId?: string | null,
};

export type ModelCommentConditionInput = {
  parentId?: ModelIDInput | null,
  owner?: ModelStringInput | null,
  tenantId?: ModelIDInput | null,
  body?: ModelStringInput | null,
  visibility?: ModelCommentVisibilityInput | null,
  archived?: ModelIntInput | null,
  and?: Array< ModelCommentConditionInput | null > | null,
  or?: Array< ModelCommentConditionInput | null > | null,
  not?: ModelCommentConditionInput | null,
  _deleted?: ModelBooleanInput | null,
  userCommentsId?: ModelIDInput | null,
};

export type ModelCommentVisibilityInput = {
  eq?: CommentVisibility | null,
  ne?: CommentVisibility | null,
};

export type UpdateCommentInput = {
  id: string,
  parentId?: string | null,
  owner?: string | null,
  tenantId?: string | null,
  body?: string | null,
  visibility?: CommentVisibility | null,
  archived?: number | null,
  _version?: number | null,
  userCommentsId?: string | null,
};

export type DeleteCommentInput = {
  id: string,
  _version?: number | null,
};

export type CreateDeliverableTypeInput = {
  id?: string | null,
  label: string,
  tenantId: string,
  icon?: DeliverableTypeIcon | null,
  defaultUnit?: DeliverableUnit | null,
  tags?: Array< string | null > | null,
  disabled?: number | null,
  _version?: number | null,
};

export type ModelDeliverableTypeConditionInput = {
  label?: ModelStringInput | null,
  tenantId?: ModelIDInput | null,
  icon?: ModelDeliverableTypeIconInput | null,
  defaultUnit?: ModelDeliverableUnitInput | null,
  tags?: ModelStringInput | null,
  disabled?: ModelIntInput | null,
  and?: Array< ModelDeliverableTypeConditionInput | null > | null,
  or?: Array< ModelDeliverableTypeConditionInput | null > | null,
  not?: ModelDeliverableTypeConditionInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelDeliverableTypeIconInput = {
  eq?: DeliverableTypeIcon | null,
  ne?: DeliverableTypeIcon | null,
};

export type ModelDeliverableUnitInput = {
  eq?: DeliverableUnit | null,
  ne?: DeliverableUnit | null,
};

export type UpdateDeliverableTypeInput = {
  id: string,
  label?: string | null,
  tenantId?: string | null,
  icon?: DeliverableTypeIcon | null,
  defaultUnit?: DeliverableUnit | null,
  tags?: Array< string | null > | null,
  disabled?: number | null,
  _version?: number | null,
};

export type DeleteDeliverableTypeInput = {
  id: string,
  _version?: number | null,
};

export type CreateDeliverableInput = {
  id?: string | null,
  tenantId: string,
  count?: number | null,
  unit?: DeliverableUnit | null,
  orderInGrid?: number | null,
  archived?: number | null,
  _version?: number | null,
  taskDeliverablesId?: string | null,
  scheduledTaskDeliverablesId?: string | null,
  deliverableTypeDeliverablesId?: string | null,
};

export type ModelDeliverableConditionInput = {
  tenantId?: ModelIDInput | null,
  count?: ModelIntInput | null,
  unit?: ModelDeliverableUnitInput | null,
  orderInGrid?: ModelIntInput | null,
  archived?: ModelIntInput | null,
  and?: Array< ModelDeliverableConditionInput | null > | null,
  or?: Array< ModelDeliverableConditionInput | null > | null,
  not?: ModelDeliverableConditionInput | null,
  _deleted?: ModelBooleanInput | null,
  taskDeliverablesId?: ModelIDInput | null,
  scheduledTaskDeliverablesId?: ModelIDInput | null,
  deliverableTypeDeliverablesId?: ModelIDInput | null,
};

export type UpdateDeliverableInput = {
  id: string,
  tenantId?: string | null,
  count?: number | null,
  unit?: DeliverableUnit | null,
  orderInGrid?: number | null,
  archived?: number | null,
  _version?: number | null,
  taskDeliverablesId?: string | null,
  scheduledTaskDeliverablesId?: string | null,
  deliverableTypeDeliverablesId?: string | null,
};

export type DeleteDeliverableInput = {
  id: string,
  _version?: number | null,
};

export type CreateRiderResponsibilityInput = {
  id?: string | null,
  tenantId: string,
  label: string,
  disabled?: number | null,
  _version?: number | null,
};

export type ModelRiderResponsibilityConditionInput = {
  tenantId?: ModelIDInput | null,
  label?: ModelStringInput | null,
  disabled?: ModelIntInput | null,
  and?: Array< ModelRiderResponsibilityConditionInput | null > | null,
  or?: Array< ModelRiderResponsibilityConditionInput | null > | null,
  not?: ModelRiderResponsibilityConditionInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type UpdateRiderResponsibilityInput = {
  id: string,
  tenantId?: string | null,
  label?: string | null,
  disabled?: number | null,
  _version?: number | null,
};

export type DeleteRiderResponsibilityInput = {
  id: string,
  _version?: number | null,
};

export type SendFeedback = {
  __typename: "SendFeedback",
  successState?: boolean | null,
};

export type ModelTenantFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  referenceIdentifier?: ModelStringInput | null,
  and?: Array< ModelTenantFilterInput | null > | null,
  or?: Array< ModelTenantFilterInput | null > | null,
  not?: ModelTenantFilterInput | null,
  _deleted?: ModelBooleanInput | null,
  tenantAdminId?: ModelIDInput | null,
};

export type ModelTenantConnection = {
  __typename: "ModelTenantConnection",
  items:  Array<Tenant | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export enum ModelSortDirection {
  ASC = "ASC",
  DESC = "DESC",
}


export type ModelUserFilterInput = {
  id?: ModelIDInput | null,
  username?: ModelStringInput | null,
  cognitoId?: ModelIDInput | null,
  tenantId?: ModelIDInput | null,
  isPrimaryAdmin?: ModelIntInput | null,
  displayName?: ModelStringInput | null,
  name?: ModelStringInput | null,
  roles?: ModelRoleInput | null,
  dateOfBirth?: ModelStringInput | null,
  riderResponsibility?: ModelStringInput | null,
  disabled?: ModelIntInput | null,
  and?: Array< ModelUserFilterInput | null > | null,
  or?: Array< ModelUserFilterInput | null > | null,
  not?: ModelUserFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelUserConnection = {
  __typename: "ModelUserConnection",
  items:  Array<User | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelPossibleRiderResponsibilitiesFilterInput = {
  id?: ModelIDInput | null,
  tenantId?: ModelIDInput | null,
  and?: Array< ModelPossibleRiderResponsibilitiesFilterInput | null > | null,
  or?: Array< ModelPossibleRiderResponsibilitiesFilterInput | null > | null,
  not?: ModelPossibleRiderResponsibilitiesFilterInput | null,
  _deleted?: ModelBooleanInput | null,
  userPossibleRiderResponsibilitiesId?: ModelIDInput | null,
  riderResponsibilityPossibleUsersId?: ModelIDInput | null,
};

export type ModelVehicleFilterInput = {
  id?: ModelIDInput | null,
  tenantId?: ModelIDInput | null,
  name?: ModelStringInput | null,
  manufacturer?: ModelStringInput | null,
  model?: ModelStringInput | null,
  dateOfManufacture?: ModelStringInput | null,
  dateOfRegistration?: ModelStringInput | null,
  disabled?: ModelIntInput | null,
  and?: Array< ModelVehicleFilterInput | null > | null,
  or?: Array< ModelVehicleFilterInput | null > | null,
  not?: ModelVehicleFilterInput | null,
  _deleted?: ModelBooleanInput | null,
  userCreatedVehiclesId?: ModelIDInput | null,
};

export type ModelVehicleAssignmentFilterInput = {
  id?: ModelIDInput | null,
  tenantId?: ModelIDInput | null,
  and?: Array< ModelVehicleAssignmentFilterInput | null > | null,
  or?: Array< ModelVehicleAssignmentFilterInput | null > | null,
  not?: ModelVehicleAssignmentFilterInput | null,
  _deleted?: ModelBooleanInput | null,
  userVehicleAssignmentsId?: ModelIDInput | null,
  vehicleAssignmentsId?: ModelIDInput | null,
};

export type ModelLocationFilterInput = {
  id?: ModelIDInput | null,
  tenantId?: ModelIDInput | null,
  name?: ModelStringInput | null,
  listed?: ModelIntInput | null,
  ward?: ModelStringInput | null,
  line1?: ModelStringInput | null,
  line2?: ModelStringInput | null,
  line3?: ModelStringInput | null,
  town?: ModelStringInput | null,
  county?: ModelStringInput | null,
  state?: ModelStringInput | null,
  country?: ModelStringInput | null,
  postcode?: ModelStringInput | null,
  what3words?: ModelStringInput | null,
  disabled?: ModelIntInput | null,
  googleMapsPlaceId?: ModelStringInput | null,
  archived?: ModelIntInput | null,
  and?: Array< ModelLocationFilterInput | null > | null,
  or?: Array< ModelLocationFilterInput | null > | null,
  not?: ModelLocationFilterInput | null,
  _deleted?: ModelBooleanInput | null,
  userCreatedLocationsId?: ModelIDInput | null,
};

export type ModelTaskFilterInput = {
  id?: ModelIDInput | null,
  tenantId?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  dateCreated?: ModelStringInput | null,
  dateCompleted?: ModelStringInput | null,
  timeOfCall?: ModelStringInput | null,
  timePickedUp?: ModelStringInput | null,
  timePickedUpSenderName?: ModelStringInput | null,
  timeDroppedOff?: ModelStringInput | null,
  timeDroppedOffRecipientName?: ModelStringInput | null,
  timeCancelled?: ModelStringInput | null,
  timeRejected?: ModelStringInput | null,
  timeRiderHome?: ModelStringInput | null,
  pickUpLocationId?: ModelIDInput | null,
  dropOffLocationId?: ModelIDInput | null,
  establishmentLocationId?: ModelIDInput | null,
  riderResponsibility?: ModelStringInput | null,
  priority?: ModelPriorityInput | null,
  status?: ModelTaskStatusInput | null,
  isRiderUsingOwnVehicle?: ModelIntInput | null,
  archived?: ModelIntInput | null,
  and?: Array< ModelTaskFilterInput | null > | null,
  or?: Array< ModelTaskFilterInput | null > | null,
  not?: ModelTaskFilterInput | null,
  _deleted?: ModelBooleanInput | null,
  userCreatedTasksId?: ModelIDInput | null,
};

export type ModelStringKeyConditionInput = {
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
};

export type ModelTaskAssigneeFilterInput = {
  id?: ModelIDInput | null,
  tenantId?: ModelIDInput | null,
  role?: ModelRoleInput | null,
  archived?: ModelIntInput | null,
  and?: Array< ModelTaskAssigneeFilterInput | null > | null,
  or?: Array< ModelTaskAssigneeFilterInput | null > | null,
  not?: ModelTaskAssigneeFilterInput | null,
  _deleted?: ModelBooleanInput | null,
  userAssignmentsId?: ModelIDInput | null,
  taskAssigneesId?: ModelIDInput | null,
};

export type ModelScheduledTaskFilterInput = {
  id?: ModelIDInput | null,
  tenantId?: ModelIDInput | null,
  cronExpression?: ModelStringInput | null,
  pickUpLocationId?: ModelIDInput | null,
  dropOffLocationId?: ModelIDInput | null,
  establishmentLocationId?: ModelIDInput | null,
  priority?: ModelPriorityInput | null,
  disabled?: ModelIntInput | null,
  and?: Array< ModelScheduledTaskFilterInput | null > | null,
  or?: Array< ModelScheduledTaskFilterInput | null > | null,
  not?: ModelScheduledTaskFilterInput | null,
  _deleted?: ModelBooleanInput | null,
  userCreatedScheduledTasksId?: ModelIDInput | null,
};

export type ModelCommentFilterInput = {
  id?: ModelIDInput | null,
  parentId?: ModelIDInput | null,
  owner?: ModelStringInput | null,
  tenantId?: ModelIDInput | null,
  body?: ModelStringInput | null,
  visibility?: ModelCommentVisibilityInput | null,
  archived?: ModelIntInput | null,
  and?: Array< ModelCommentFilterInput | null > | null,
  or?: Array< ModelCommentFilterInput | null > | null,
  not?: ModelCommentFilterInput | null,
  _deleted?: ModelBooleanInput | null,
  userCommentsId?: ModelIDInput | null,
};

export type ModelDeliverableTypeFilterInput = {
  id?: ModelIDInput | null,
  label?: ModelStringInput | null,
  tenantId?: ModelIDInput | null,
  icon?: ModelDeliverableTypeIconInput | null,
  defaultUnit?: ModelDeliverableUnitInput | null,
  tags?: ModelStringInput | null,
  disabled?: ModelIntInput | null,
  and?: Array< ModelDeliverableTypeFilterInput | null > | null,
  or?: Array< ModelDeliverableTypeFilterInput | null > | null,
  not?: ModelDeliverableTypeFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelDeliverableTypeConnection = {
  __typename: "ModelDeliverableTypeConnection",
  items:  Array<DeliverableType | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelDeliverableFilterInput = {
  id?: ModelIDInput | null,
  tenantId?: ModelIDInput | null,
  count?: ModelIntInput | null,
  unit?: ModelDeliverableUnitInput | null,
  orderInGrid?: ModelIntInput | null,
  archived?: ModelIntInput | null,
  and?: Array< ModelDeliverableFilterInput | null > | null,
  or?: Array< ModelDeliverableFilterInput | null > | null,
  not?: ModelDeliverableFilterInput | null,
  _deleted?: ModelBooleanInput | null,
  taskDeliverablesId?: ModelIDInput | null,
  scheduledTaskDeliverablesId?: ModelIDInput | null,
  deliverableTypeDeliverablesId?: ModelIDInput | null,
};

export type ModelRiderResponsibilityFilterInput = {
  id?: ModelIDInput | null,
  tenantId?: ModelIDInput | null,
  label?: ModelStringInput | null,
  disabled?: ModelIntInput | null,
  and?: Array< ModelRiderResponsibilityFilterInput | null > | null,
  or?: Array< ModelRiderResponsibilityFilterInput | null > | null,
  not?: ModelRiderResponsibilityFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelRiderResponsibilityConnection = {
  __typename: "ModelRiderResponsibilityConnection",
  items:  Array<RiderResponsibility | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelSubscriptionTenantFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  referenceIdentifier?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionTenantFilterInput | null > | null,
  or?: Array< ModelSubscriptionTenantFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionIDInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  in?: Array< string | null > | null,
  notIn?: Array< string | null > | null,
};

export type ModelSubscriptionStringInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  in?: Array< string | null > | null,
  notIn?: Array< string | null > | null,
};

export type ModelSubscriptionUserFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  username?: ModelSubscriptionStringInput | null,
  tenantId?: ModelSubscriptionIDInput | null,
  isPrimaryAdmin?: ModelSubscriptionIntInput | null,
  displayName?: ModelSubscriptionStringInput | null,
  name?: ModelSubscriptionStringInput | null,
  roles?: ModelSubscriptionStringInput | null,
  dateOfBirth?: ModelSubscriptionStringInput | null,
  riderResponsibility?: ModelSubscriptionStringInput | null,
  disabled?: ModelSubscriptionIntInput | null,
  and?: Array< ModelSubscriptionUserFilterInput | null > | null,
  or?: Array< ModelSubscriptionUserFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionIntInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  in?: Array< number | null > | null,
  notIn?: Array< number | null > | null,
};

export type ModelSubscriptionPossibleRiderResponsibilitiesFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  tenantId?: ModelSubscriptionIDInput | null,
  and?: Array< ModelSubscriptionPossibleRiderResponsibilitiesFilterInput | null > | null,
  or?: Array< ModelSubscriptionPossibleRiderResponsibilitiesFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionVehicleFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  tenantId?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  manufacturer?: ModelSubscriptionStringInput | null,
  model?: ModelSubscriptionStringInput | null,
  dateOfManufacture?: ModelSubscriptionStringInput | null,
  dateOfRegistration?: ModelSubscriptionStringInput | null,
  disabled?: ModelSubscriptionIntInput | null,
  and?: Array< ModelSubscriptionVehicleFilterInput | null > | null,
  or?: Array< ModelSubscriptionVehicleFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionVehicleAssignmentFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  tenantId?: ModelSubscriptionIDInput | null,
  and?: Array< ModelSubscriptionVehicleAssignmentFilterInput | null > | null,
  or?: Array< ModelSubscriptionVehicleAssignmentFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionLocationFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  tenantId?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  listed?: ModelSubscriptionIntInput | null,
  ward?: ModelSubscriptionStringInput | null,
  line1?: ModelSubscriptionStringInput | null,
  line2?: ModelSubscriptionStringInput | null,
  line3?: ModelSubscriptionStringInput | null,
  town?: ModelSubscriptionStringInput | null,
  county?: ModelSubscriptionStringInput | null,
  state?: ModelSubscriptionStringInput | null,
  country?: ModelSubscriptionStringInput | null,
  postcode?: ModelSubscriptionStringInput | null,
  what3words?: ModelSubscriptionStringInput | null,
  disabled?: ModelSubscriptionIntInput | null,
  googleMapsPlaceId?: ModelSubscriptionStringInput | null,
  archived?: ModelSubscriptionIntInput | null,
  and?: Array< ModelSubscriptionLocationFilterInput | null > | null,
  or?: Array< ModelSubscriptionLocationFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionTaskFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  tenantId?: ModelSubscriptionIDInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  dateCreated?: ModelSubscriptionStringInput | null,
  dateCompleted?: ModelSubscriptionStringInput | null,
  timeOfCall?: ModelSubscriptionStringInput | null,
  timePickedUp?: ModelSubscriptionStringInput | null,
  timePickedUpSenderName?: ModelSubscriptionStringInput | null,
  timeDroppedOff?: ModelSubscriptionStringInput | null,
  timeDroppedOffRecipientName?: ModelSubscriptionStringInput | null,
  timeCancelled?: ModelSubscriptionStringInput | null,
  timeRejected?: ModelSubscriptionStringInput | null,
  timeRiderHome?: ModelSubscriptionStringInput | null,
  pickUpLocationId?: ModelSubscriptionIDInput | null,
  dropOffLocationId?: ModelSubscriptionIDInput | null,
  establishmentLocationId?: ModelSubscriptionIDInput | null,
  riderResponsibility?: ModelSubscriptionStringInput | null,
  priority?: ModelSubscriptionStringInput | null,
  status?: ModelSubscriptionStringInput | null,
  isRiderUsingOwnVehicle?: ModelSubscriptionIntInput | null,
  archived?: ModelSubscriptionIntInput | null,
  and?: Array< ModelSubscriptionTaskFilterInput | null > | null,
  or?: Array< ModelSubscriptionTaskFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionTaskAssigneeFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  tenantId?: ModelSubscriptionIDInput | null,
  role?: ModelSubscriptionStringInput | null,
  archived?: ModelSubscriptionIntInput | null,
  and?: Array< ModelSubscriptionTaskAssigneeFilterInput | null > | null,
  or?: Array< ModelSubscriptionTaskAssigneeFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionScheduledTaskFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  tenantId?: ModelSubscriptionIDInput | null,
  cronExpression?: ModelSubscriptionStringInput | null,
  pickUpLocationId?: ModelSubscriptionIDInput | null,
  dropOffLocationId?: ModelSubscriptionIDInput | null,
  establishmentLocationId?: ModelSubscriptionIDInput | null,
  priority?: ModelSubscriptionStringInput | null,
  disabled?: ModelSubscriptionIntInput | null,
  and?: Array< ModelSubscriptionScheduledTaskFilterInput | null > | null,
  or?: Array< ModelSubscriptionScheduledTaskFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionCommentFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  parentId?: ModelSubscriptionIDInput | null,
  tenantId?: ModelSubscriptionIDInput | null,
  body?: ModelSubscriptionStringInput | null,
  visibility?: ModelSubscriptionStringInput | null,
  archived?: ModelSubscriptionIntInput | null,
  and?: Array< ModelSubscriptionCommentFilterInput | null > | null,
  or?: Array< ModelSubscriptionCommentFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionDeliverableTypeFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  label?: ModelSubscriptionStringInput | null,
  tenantId?: ModelSubscriptionIDInput | null,
  icon?: ModelSubscriptionStringInput | null,
  defaultUnit?: ModelSubscriptionStringInput | null,
  tags?: ModelSubscriptionStringInput | null,
  disabled?: ModelSubscriptionIntInput | null,
  and?: Array< ModelSubscriptionDeliverableTypeFilterInput | null > | null,
  or?: Array< ModelSubscriptionDeliverableTypeFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionDeliverableFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  tenantId?: ModelSubscriptionIDInput | null,
  count?: ModelSubscriptionIntInput | null,
  unit?: ModelSubscriptionStringInput | null,
  orderInGrid?: ModelSubscriptionIntInput | null,
  archived?: ModelSubscriptionIntInput | null,
  and?: Array< ModelSubscriptionDeliverableFilterInput | null > | null,
  or?: Array< ModelSubscriptionDeliverableFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionRiderResponsibilityFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  tenantId?: ModelSubscriptionIDInput | null,
  label?: ModelSubscriptionStringInput | null,
  disabled?: ModelSubscriptionIntInput | null,
  and?: Array< ModelSubscriptionRiderResponsibilityFilterInput | null > | null,
  or?: Array< ModelSubscriptionRiderResponsibilityFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type CreateTenantMutationVariables = {
  input: CreateTenantInput,
  condition?: ModelTenantConditionInput | null,
};

export type CreateTenantMutation = {
  createTenant?:  {
    __typename: "Tenant",
    id: string,
    name: string,
    referenceIdentifier: string,
    admin:  {
      __typename: "User",
      id: string,
      username: string,
      cognitoId: string,
      tenantId: string,
      isPrimaryAdmin?: number | null,
      displayName: string,
      name?: string | null,
      roles: Array< Role >,
      dateOfBirth?: string | null,
      riderResponsibility?: string | null,
      disabled?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    tenantAdminId: string,
  } | null,
};

export type UpdateTenantMutationVariables = {
  input: UpdateTenantInput,
  condition?: ModelTenantConditionInput | null,
};

export type UpdateTenantMutation = {
  updateTenant?:  {
    __typename: "Tenant",
    id: string,
    name: string,
    referenceIdentifier: string,
    admin:  {
      __typename: "User",
      id: string,
      username: string,
      cognitoId: string,
      tenantId: string,
      isPrimaryAdmin?: number | null,
      displayName: string,
      name?: string | null,
      roles: Array< Role >,
      dateOfBirth?: string | null,
      riderResponsibility?: string | null,
      disabled?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    tenantAdminId: string,
  } | null,
};

export type DeleteTenantMutationVariables = {
  input: DeleteTenantInput,
  condition?: ModelTenantConditionInput | null,
};

export type DeleteTenantMutation = {
  deleteTenant?:  {
    __typename: "Tenant",
    id: string,
    name: string,
    referenceIdentifier: string,
    admin:  {
      __typename: "User",
      id: string,
      username: string,
      cognitoId: string,
      tenantId: string,
      isPrimaryAdmin?: number | null,
      displayName: string,
      name?: string | null,
      roles: Array< Role >,
      dateOfBirth?: string | null,
      riderResponsibility?: string | null,
      disabled?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    tenantAdminId: string,
  } | null,
};

export type CreateUserMutationVariables = {
  input: CreateUserInput,
  condition?: ModelUserConditionInput | null,
};

export type CreateUserMutation = {
  createUser?:  {
    __typename: "User",
    id: string,
    username: string,
    cognitoId: string,
    tenantId: string,
    isPrimaryAdmin?: number | null,
    contact?:  {
      __typename: "AddressAndContactDetails",
      name?: string | null,
      telephoneNumber?: string | null,
      mobileNumber?: string | null,
      emailAddress?: string | null,
      ward?: string | null,
      line1?: string | null,
      line2?: string | null,
      line3?: string | null,
      town?: string | null,
      county?: string | null,
      state?: string | null,
      country?: string | null,
      postcode?: string | null,
      what3words?: string | null,
    } | null,
    displayName: string,
    name?: string | null,
    roles: Array< Role >,
    dateOfBirth?: string | null,
    riderResponsibility?: string | null,
    possibleRiderResponsibilities?:  {
      __typename: "ModelPossibleRiderResponsibilitiesConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    profilePicture?:  {
      __typename: "S3Object",
      bucket: string,
      key: string,
      region: string,
    } | null,
    comments?:  {
      __typename: "ModelCommentConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    assignments?:  {
      __typename: "ModelTaskAssigneeConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    vehicleAssignments?:  {
      __typename: "ModelVehicleAssignmentConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdTasks?:  {
      __typename: "ModelTaskConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdLocations?:  {
      __typename: "ModelLocationConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdVehicles?:  {
      __typename: "ModelVehicleConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdScheduledTasks?:  {
      __typename: "ModelScheduledTaskConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    disabled?: number | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateUserMutationVariables = {
  input: UpdateUserInput,
  condition?: ModelUserConditionInput | null,
};

export type UpdateUserMutation = {
  updateUser?:  {
    __typename: "User",
    id: string,
    username: string,
    cognitoId: string,
    tenantId: string,
    isPrimaryAdmin?: number | null,
    contact?:  {
      __typename: "AddressAndContactDetails",
      name?: string | null,
      telephoneNumber?: string | null,
      mobileNumber?: string | null,
      emailAddress?: string | null,
      ward?: string | null,
      line1?: string | null,
      line2?: string | null,
      line3?: string | null,
      town?: string | null,
      county?: string | null,
      state?: string | null,
      country?: string | null,
      postcode?: string | null,
      what3words?: string | null,
    } | null,
    displayName: string,
    name?: string | null,
    roles: Array< Role >,
    dateOfBirth?: string | null,
    riderResponsibility?: string | null,
    possibleRiderResponsibilities?:  {
      __typename: "ModelPossibleRiderResponsibilitiesConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    profilePicture?:  {
      __typename: "S3Object",
      bucket: string,
      key: string,
      region: string,
    } | null,
    comments?:  {
      __typename: "ModelCommentConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    assignments?:  {
      __typename: "ModelTaskAssigneeConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    vehicleAssignments?:  {
      __typename: "ModelVehicleAssignmentConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdTasks?:  {
      __typename: "ModelTaskConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdLocations?:  {
      __typename: "ModelLocationConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdVehicles?:  {
      __typename: "ModelVehicleConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdScheduledTasks?:  {
      __typename: "ModelScheduledTaskConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    disabled?: number | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteUserMutationVariables = {
  input: DeleteUserInput,
  condition?: ModelUserConditionInput | null,
};

export type DeleteUserMutation = {
  deleteUser?:  {
    __typename: "User",
    id: string,
    username: string,
    cognitoId: string,
    tenantId: string,
    isPrimaryAdmin?: number | null,
    contact?:  {
      __typename: "AddressAndContactDetails",
      name?: string | null,
      telephoneNumber?: string | null,
      mobileNumber?: string | null,
      emailAddress?: string | null,
      ward?: string | null,
      line1?: string | null,
      line2?: string | null,
      line3?: string | null,
      town?: string | null,
      county?: string | null,
      state?: string | null,
      country?: string | null,
      postcode?: string | null,
      what3words?: string | null,
    } | null,
    displayName: string,
    name?: string | null,
    roles: Array< Role >,
    dateOfBirth?: string | null,
    riderResponsibility?: string | null,
    possibleRiderResponsibilities?:  {
      __typename: "ModelPossibleRiderResponsibilitiesConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    profilePicture?:  {
      __typename: "S3Object",
      bucket: string,
      key: string,
      region: string,
    } | null,
    comments?:  {
      __typename: "ModelCommentConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    assignments?:  {
      __typename: "ModelTaskAssigneeConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    vehicleAssignments?:  {
      __typename: "ModelVehicleAssignmentConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdTasks?:  {
      __typename: "ModelTaskConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdLocations?:  {
      __typename: "ModelLocationConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdVehicles?:  {
      __typename: "ModelVehicleConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdScheduledTasks?:  {
      __typename: "ModelScheduledTaskConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    disabled?: number | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreatePossibleRiderResponsibilitiesMutationVariables = {
  input: CreatePossibleRiderResponsibilitiesInput,
  condition?: ModelPossibleRiderResponsibilitiesConditionInput | null,
};

export type CreatePossibleRiderResponsibilitiesMutation = {
  createPossibleRiderResponsibilities?:  {
    __typename: "PossibleRiderResponsibilities",
    id: string,
    tenantId: string,
    user:  {
      __typename: "User",
      id: string,
      username: string,
      cognitoId: string,
      tenantId: string,
      isPrimaryAdmin?: number | null,
      displayName: string,
      name?: string | null,
      roles: Array< Role >,
      dateOfBirth?: string | null,
      riderResponsibility?: string | null,
      disabled?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    riderResponsibility:  {
      __typename: "RiderResponsibility",
      id: string,
      tenantId: string,
      label: string,
      disabled?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    userPossibleRiderResponsibilitiesId?: string | null,
    riderResponsibilityPossibleUsersId?: string | null,
  } | null,
};

export type UpdatePossibleRiderResponsibilitiesMutationVariables = {
  input: UpdatePossibleRiderResponsibilitiesInput,
  condition?: ModelPossibleRiderResponsibilitiesConditionInput | null,
};

export type UpdatePossibleRiderResponsibilitiesMutation = {
  updatePossibleRiderResponsibilities?:  {
    __typename: "PossibleRiderResponsibilities",
    id: string,
    tenantId: string,
    user:  {
      __typename: "User",
      id: string,
      username: string,
      cognitoId: string,
      tenantId: string,
      isPrimaryAdmin?: number | null,
      displayName: string,
      name?: string | null,
      roles: Array< Role >,
      dateOfBirth?: string | null,
      riderResponsibility?: string | null,
      disabled?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    riderResponsibility:  {
      __typename: "RiderResponsibility",
      id: string,
      tenantId: string,
      label: string,
      disabled?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    userPossibleRiderResponsibilitiesId?: string | null,
    riderResponsibilityPossibleUsersId?: string | null,
  } | null,
};

export type DeletePossibleRiderResponsibilitiesMutationVariables = {
  input: DeletePossibleRiderResponsibilitiesInput,
  condition?: ModelPossibleRiderResponsibilitiesConditionInput | null,
};

export type DeletePossibleRiderResponsibilitiesMutation = {
  deletePossibleRiderResponsibilities?:  {
    __typename: "PossibleRiderResponsibilities",
    id: string,
    tenantId: string,
    user:  {
      __typename: "User",
      id: string,
      username: string,
      cognitoId: string,
      tenantId: string,
      isPrimaryAdmin?: number | null,
      displayName: string,
      name?: string | null,
      roles: Array< Role >,
      dateOfBirth?: string | null,
      riderResponsibility?: string | null,
      disabled?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    riderResponsibility:  {
      __typename: "RiderResponsibility",
      id: string,
      tenantId: string,
      label: string,
      disabled?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    userPossibleRiderResponsibilitiesId?: string | null,
    riderResponsibilityPossibleUsersId?: string | null,
  } | null,
};

export type CreateVehicleMutationVariables = {
  input: CreateVehicleInput,
  condition?: ModelVehicleConditionInput | null,
};

export type CreateVehicleMutation = {
  createVehicle?:  {
    __typename: "Vehicle",
    id: string,
    tenantId: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      username: string,
      cognitoId: string,
      tenantId: string,
      isPrimaryAdmin?: number | null,
      displayName: string,
      name?: string | null,
      roles: Array< Role >,
      dateOfBirth?: string | null,
      riderResponsibility?: string | null,
      disabled?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    name?: string | null,
    manufacturer?: string | null,
    model?: string | null,
    dateOfManufacture?: string | null,
    dateOfRegistration?: string | null,
    assignments?:  {
      __typename: "ModelVehicleAssignmentConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    comments?:  {
      __typename: "ModelCommentConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    disabled?: number | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    userCreatedVehiclesId?: string | null,
  } | null,
};

export type UpdateVehicleMutationVariables = {
  input: UpdateVehicleInput,
  condition?: ModelVehicleConditionInput | null,
};

export type UpdateVehicleMutation = {
  updateVehicle?:  {
    __typename: "Vehicle",
    id: string,
    tenantId: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      username: string,
      cognitoId: string,
      tenantId: string,
      isPrimaryAdmin?: number | null,
      displayName: string,
      name?: string | null,
      roles: Array< Role >,
      dateOfBirth?: string | null,
      riderResponsibility?: string | null,
      disabled?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    name?: string | null,
    manufacturer?: string | null,
    model?: string | null,
    dateOfManufacture?: string | null,
    dateOfRegistration?: string | null,
    assignments?:  {
      __typename: "ModelVehicleAssignmentConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    comments?:  {
      __typename: "ModelCommentConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    disabled?: number | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    userCreatedVehiclesId?: string | null,
  } | null,
};

export type DeleteVehicleMutationVariables = {
  input: DeleteVehicleInput,
  condition?: ModelVehicleConditionInput | null,
};

export type DeleteVehicleMutation = {
  deleteVehicle?:  {
    __typename: "Vehicle",
    id: string,
    tenantId: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      username: string,
      cognitoId: string,
      tenantId: string,
      isPrimaryAdmin?: number | null,
      displayName: string,
      name?: string | null,
      roles: Array< Role >,
      dateOfBirth?: string | null,
      riderResponsibility?: string | null,
      disabled?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    name?: string | null,
    manufacturer?: string | null,
    model?: string | null,
    dateOfManufacture?: string | null,
    dateOfRegistration?: string | null,
    assignments?:  {
      __typename: "ModelVehicleAssignmentConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    comments?:  {
      __typename: "ModelCommentConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    disabled?: number | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    userCreatedVehiclesId?: string | null,
  } | null,
};

export type CreateVehicleAssignmentMutationVariables = {
  input: CreateVehicleAssignmentInput,
  condition?: ModelVehicleAssignmentConditionInput | null,
};

export type CreateVehicleAssignmentMutation = {
  createVehicleAssignment?:  {
    __typename: "VehicleAssignment",
    id: string,
    tenantId: string,
    vehicle:  {
      __typename: "Vehicle",
      id: string,
      tenantId: string,
      name?: string | null,
      manufacturer?: string | null,
      model?: string | null,
      dateOfManufacture?: string | null,
      dateOfRegistration?: string | null,
      disabled?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      userCreatedVehiclesId?: string | null,
    },
    assignee:  {
      __typename: "User",
      id: string,
      username: string,
      cognitoId: string,
      tenantId: string,
      isPrimaryAdmin?: number | null,
      displayName: string,
      name?: string | null,
      roles: Array< Role >,
      dateOfBirth?: string | null,
      riderResponsibility?: string | null,
      disabled?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    userVehicleAssignmentsId?: string | null,
    vehicleAssignmentsId?: string | null,
  } | null,
};

export type UpdateVehicleAssignmentMutationVariables = {
  input: UpdateVehicleAssignmentInput,
  condition?: ModelVehicleAssignmentConditionInput | null,
};

export type UpdateVehicleAssignmentMutation = {
  updateVehicleAssignment?:  {
    __typename: "VehicleAssignment",
    id: string,
    tenantId: string,
    vehicle:  {
      __typename: "Vehicle",
      id: string,
      tenantId: string,
      name?: string | null,
      manufacturer?: string | null,
      model?: string | null,
      dateOfManufacture?: string | null,
      dateOfRegistration?: string | null,
      disabled?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      userCreatedVehiclesId?: string | null,
    },
    assignee:  {
      __typename: "User",
      id: string,
      username: string,
      cognitoId: string,
      tenantId: string,
      isPrimaryAdmin?: number | null,
      displayName: string,
      name?: string | null,
      roles: Array< Role >,
      dateOfBirth?: string | null,
      riderResponsibility?: string | null,
      disabled?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    userVehicleAssignmentsId?: string | null,
    vehicleAssignmentsId?: string | null,
  } | null,
};

export type DeleteVehicleAssignmentMutationVariables = {
  input: DeleteVehicleAssignmentInput,
  condition?: ModelVehicleAssignmentConditionInput | null,
};

export type DeleteVehicleAssignmentMutation = {
  deleteVehicleAssignment?:  {
    __typename: "VehicleAssignment",
    id: string,
    tenantId: string,
    vehicle:  {
      __typename: "Vehicle",
      id: string,
      tenantId: string,
      name?: string | null,
      manufacturer?: string | null,
      model?: string | null,
      dateOfManufacture?: string | null,
      dateOfRegistration?: string | null,
      disabled?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      userCreatedVehiclesId?: string | null,
    },
    assignee:  {
      __typename: "User",
      id: string,
      username: string,
      cognitoId: string,
      tenantId: string,
      isPrimaryAdmin?: number | null,
      displayName: string,
      name?: string | null,
      roles: Array< Role >,
      dateOfBirth?: string | null,
      riderResponsibility?: string | null,
      disabled?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    userVehicleAssignmentsId?: string | null,
    vehicleAssignmentsId?: string | null,
  } | null,
};

export type CreateLocationMutationVariables = {
  input: CreateLocationInput,
  condition?: ModelLocationConditionInput | null,
};

export type CreateLocationMutation = {
  createLocation?:  {
    __typename: "Location",
    id: string,
    tenantId: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      username: string,
      cognitoId: string,
      tenantId: string,
      isPrimaryAdmin?: number | null,
      displayName: string,
      name?: string | null,
      roles: Array< Role >,
      dateOfBirth?: string | null,
      riderResponsibility?: string | null,
      disabled?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    name?: string | null,
    listed?: number | null,
    contact?:  {
      __typename: "AddressAndContactDetails",
      name?: string | null,
      telephoneNumber?: string | null,
      mobileNumber?: string | null,
      emailAddress?: string | null,
      ward?: string | null,
      line1?: string | null,
      line2?: string | null,
      line3?: string | null,
      town?: string | null,
      county?: string | null,
      state?: string | null,
      country?: string | null,
      postcode?: string | null,
      what3words?: string | null,
    } | null,
    ward?: string | null,
    line1?: string | null,
    line2?: string | null,
    line3?: string | null,
    town?: string | null,
    county?: string | null,
    state?: string | null,
    country?: string | null,
    postcode?: string | null,
    what3words?: string | null,
    tasksAsPickUp?:  {
      __typename: "ModelTaskConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    tasksAsDropOff?:  {
      __typename: "ModelTaskConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    taskAsEstablishment?:  {
      __typename: "ModelTaskConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    scheduledTasksAsPickUp?:  {
      __typename: "ModelScheduledTaskConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    scheduledTasksAsDropOff?:  {
      __typename: "ModelScheduledTaskConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    scheduledTasksAsEstablishment?:  {
      __typename: "ModelScheduledTaskConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    comments?:  {
      __typename: "ModelCommentConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    disabled?: number | null,
    googleMapsPlaceId?: string | null,
    archived?: number | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    userCreatedLocationsId?: string | null,
  } | null,
};

export type UpdateLocationMutationVariables = {
  input: UpdateLocationInput,
  condition?: ModelLocationConditionInput | null,
};

export type UpdateLocationMutation = {
  updateLocation?:  {
    __typename: "Location",
    id: string,
    tenantId: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      username: string,
      cognitoId: string,
      tenantId: string,
      isPrimaryAdmin?: number | null,
      displayName: string,
      name?: string | null,
      roles: Array< Role >,
      dateOfBirth?: string | null,
      riderResponsibility?: string | null,
      disabled?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    name?: string | null,
    listed?: number | null,
    contact?:  {
      __typename: "AddressAndContactDetails",
      name?: string | null,
      telephoneNumber?: string | null,
      mobileNumber?: string | null,
      emailAddress?: string | null,
      ward?: string | null,
      line1?: string | null,
      line2?: string | null,
      line3?: string | null,
      town?: string | null,
      county?: string | null,
      state?: string | null,
      country?: string | null,
      postcode?: string | null,
      what3words?: string | null,
    } | null,
    ward?: string | null,
    line1?: string | null,
    line2?: string | null,
    line3?: string | null,
    town?: string | null,
    county?: string | null,
    state?: string | null,
    country?: string | null,
    postcode?: string | null,
    what3words?: string | null,
    tasksAsPickUp?:  {
      __typename: "ModelTaskConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    tasksAsDropOff?:  {
      __typename: "ModelTaskConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    taskAsEstablishment?:  {
      __typename: "ModelTaskConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    scheduledTasksAsPickUp?:  {
      __typename: "ModelScheduledTaskConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    scheduledTasksAsDropOff?:  {
      __typename: "ModelScheduledTaskConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    scheduledTasksAsEstablishment?:  {
      __typename: "ModelScheduledTaskConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    comments?:  {
      __typename: "ModelCommentConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    disabled?: number | null,
    googleMapsPlaceId?: string | null,
    archived?: number | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    userCreatedLocationsId?: string | null,
  } | null,
};

export type DeleteLocationMutationVariables = {
  input: DeleteLocationInput,
  condition?: ModelLocationConditionInput | null,
};

export type DeleteLocationMutation = {
  deleteLocation?:  {
    __typename: "Location",
    id: string,
    tenantId: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      username: string,
      cognitoId: string,
      tenantId: string,
      isPrimaryAdmin?: number | null,
      displayName: string,
      name?: string | null,
      roles: Array< Role >,
      dateOfBirth?: string | null,
      riderResponsibility?: string | null,
      disabled?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    name?: string | null,
    listed?: number | null,
    contact?:  {
      __typename: "AddressAndContactDetails",
      name?: string | null,
      telephoneNumber?: string | null,
      mobileNumber?: string | null,
      emailAddress?: string | null,
      ward?: string | null,
      line1?: string | null,
      line2?: string | null,
      line3?: string | null,
      town?: string | null,
      county?: string | null,
      state?: string | null,
      country?: string | null,
      postcode?: string | null,
      what3words?: string | null,
    } | null,
    ward?: string | null,
    line1?: string | null,
    line2?: string | null,
    line3?: string | null,
    town?: string | null,
    county?: string | null,
    state?: string | null,
    country?: string | null,
    postcode?: string | null,
    what3words?: string | null,
    tasksAsPickUp?:  {
      __typename: "ModelTaskConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    tasksAsDropOff?:  {
      __typename: "ModelTaskConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    taskAsEstablishment?:  {
      __typename: "ModelTaskConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    scheduledTasksAsPickUp?:  {
      __typename: "ModelScheduledTaskConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    scheduledTasksAsDropOff?:  {
      __typename: "ModelScheduledTaskConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    scheduledTasksAsEstablishment?:  {
      __typename: "ModelScheduledTaskConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    comments?:  {
      __typename: "ModelCommentConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    disabled?: number | null,
    googleMapsPlaceId?: string | null,
    archived?: number | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    userCreatedLocationsId?: string | null,
  } | null,
};

export type CreateTaskMutationVariables = {
  input: CreateTaskInput,
  condition?: ModelTaskConditionInput | null,
};

export type CreateTaskMutation = {
  createTask?:  {
    __typename: "Task",
    id: string,
    tenantId: string,
    createdAt?: string | null,
    createdBy?:  {
      __typename: "User",
      id: string,
      username: string,
      cognitoId: string,
      tenantId: string,
      isPrimaryAdmin?: number | null,
      displayName: string,
      name?: string | null,
      roles: Array< Role >,
      dateOfBirth?: string | null,
      riderResponsibility?: string | null,
      disabled?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    dateCreated: string,
    dateCompleted?: string | null,
    timeOfCall?: string | null,
    timePickedUp?: string | null,
    timePickedUpSenderName?: string | null,
    timeDroppedOff?: string | null,
    timeDroppedOffRecipientName?: string | null,
    timeCancelled?: string | null,
    timeRejected?: string | null,
    timeRiderHome?: string | null,
    requesterContact?:  {
      __typename: "AddressAndContactDetails",
      name?: string | null,
      telephoneNumber?: string | null,
      mobileNumber?: string | null,
      emailAddress?: string | null,
      ward?: string | null,
      line1?: string | null,
      line2?: string | null,
      line3?: string | null,
      town?: string | null,
      county?: string | null,
      state?: string | null,
      country?: string | null,
      postcode?: string | null,
      what3words?: string | null,
    } | null,
    pickUpLocationId?: string | null,
    dropOffLocationId?: string | null,
    establishmentLocationId?: string | null,
    pickUpLocation?:  {
      __typename: "Location",
      id: string,
      tenantId: string,
      name?: string | null,
      listed?: number | null,
      ward?: string | null,
      line1?: string | null,
      line2?: string | null,
      line3?: string | null,
      town?: string | null,
      county?: string | null,
      state?: string | null,
      country?: string | null,
      postcode?: string | null,
      what3words?: string | null,
      disabled?: number | null,
      googleMapsPlaceId?: string | null,
      archived?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      userCreatedLocationsId?: string | null,
    } | null,
    dropOffLocation?:  {
      __typename: "Location",
      id: string,
      tenantId: string,
      name?: string | null,
      listed?: number | null,
      ward?: string | null,
      line1?: string | null,
      line2?: string | null,
      line3?: string | null,
      town?: string | null,
      county?: string | null,
      state?: string | null,
      country?: string | null,
      postcode?: string | null,
      what3words?: string | null,
      disabled?: number | null,
      googleMapsPlaceId?: string | null,
      archived?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      userCreatedLocationsId?: string | null,
    } | null,
    establishmentLocation?:  {
      __typename: "Location",
      id: string,
      tenantId: string,
      name?: string | null,
      listed?: number | null,
      ward?: string | null,
      line1?: string | null,
      line2?: string | null,
      line3?: string | null,
      town?: string | null,
      county?: string | null,
      state?: string | null,
      country?: string | null,
      postcode?: string | null,
      what3words?: string | null,
      disabled?: number | null,
      googleMapsPlaceId?: string | null,
      archived?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      userCreatedLocationsId?: string | null,
    } | null,
    riderResponsibility?: string | null,
    assignees?:  {
      __typename: "ModelTaskAssigneeConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    priority?: Priority | null,
    deliverables?:  {
      __typename: "ModelDeliverableConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    comments?:  {
      __typename: "ModelCommentConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    status?: TaskStatus | null,
    isRiderUsingOwnVehicle?: number | null,
    archived?: number | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    userCreatedTasksId?: string | null,
  } | null,
};

export type UpdateTaskMutationVariables = {
  input: UpdateTaskInput,
  condition?: ModelTaskConditionInput | null,
};

export type UpdateTaskMutation = {
  updateTask?:  {
    __typename: "Task",
    id: string,
    tenantId: string,
    createdAt?: string | null,
    createdBy?:  {
      __typename: "User",
      id: string,
      username: string,
      cognitoId: string,
      tenantId: string,
      isPrimaryAdmin?: number | null,
      displayName: string,
      name?: string | null,
      roles: Array< Role >,
      dateOfBirth?: string | null,
      riderResponsibility?: string | null,
      disabled?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    dateCreated: string,
    dateCompleted?: string | null,
    timeOfCall?: string | null,
    timePickedUp?: string | null,
    timePickedUpSenderName?: string | null,
    timeDroppedOff?: string | null,
    timeDroppedOffRecipientName?: string | null,
    timeCancelled?: string | null,
    timeRejected?: string | null,
    timeRiderHome?: string | null,
    requesterContact?:  {
      __typename: "AddressAndContactDetails",
      name?: string | null,
      telephoneNumber?: string | null,
      mobileNumber?: string | null,
      emailAddress?: string | null,
      ward?: string | null,
      line1?: string | null,
      line2?: string | null,
      line3?: string | null,
      town?: string | null,
      county?: string | null,
      state?: string | null,
      country?: string | null,
      postcode?: string | null,
      what3words?: string | null,
    } | null,
    pickUpLocationId?: string | null,
    dropOffLocationId?: string | null,
    establishmentLocationId?: string | null,
    pickUpLocation?:  {
      __typename: "Location",
      id: string,
      tenantId: string,
      name?: string | null,
      listed?: number | null,
      ward?: string | null,
      line1?: string | null,
      line2?: string | null,
      line3?: string | null,
      town?: string | null,
      county?: string | null,
      state?: string | null,
      country?: string | null,
      postcode?: string | null,
      what3words?: string | null,
      disabled?: number | null,
      googleMapsPlaceId?: string | null,
      archived?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      userCreatedLocationsId?: string | null,
    } | null,
    dropOffLocation?:  {
      __typename: "Location",
      id: string,
      tenantId: string,
      name?: string | null,
      listed?: number | null,
      ward?: string | null,
      line1?: string | null,
      line2?: string | null,
      line3?: string | null,
      town?: string | null,
      county?: string | null,
      state?: string | null,
      country?: string | null,
      postcode?: string | null,
      what3words?: string | null,
      disabled?: number | null,
      googleMapsPlaceId?: string | null,
      archived?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      userCreatedLocationsId?: string | null,
    } | null,
    establishmentLocation?:  {
      __typename: "Location",
      id: string,
      tenantId: string,
      name?: string | null,
      listed?: number | null,
      ward?: string | null,
      line1?: string | null,
      line2?: string | null,
      line3?: string | null,
      town?: string | null,
      county?: string | null,
      state?: string | null,
      country?: string | null,
      postcode?: string | null,
      what3words?: string | null,
      disabled?: number | null,
      googleMapsPlaceId?: string | null,
      archived?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      userCreatedLocationsId?: string | null,
    } | null,
    riderResponsibility?: string | null,
    assignees?:  {
      __typename: "ModelTaskAssigneeConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    priority?: Priority | null,
    deliverables?:  {
      __typename: "ModelDeliverableConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    comments?:  {
      __typename: "ModelCommentConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    status?: TaskStatus | null,
    isRiderUsingOwnVehicle?: number | null,
    archived?: number | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    userCreatedTasksId?: string | null,
  } | null,
};

export type DeleteTaskMutationVariables = {
  input: DeleteTaskInput,
  condition?: ModelTaskConditionInput | null,
};

export type DeleteTaskMutation = {
  deleteTask?:  {
    __typename: "Task",
    id: string,
    tenantId: string,
    createdAt?: string | null,
    createdBy?:  {
      __typename: "User",
      id: string,
      username: string,
      cognitoId: string,
      tenantId: string,
      isPrimaryAdmin?: number | null,
      displayName: string,
      name?: string | null,
      roles: Array< Role >,
      dateOfBirth?: string | null,
      riderResponsibility?: string | null,
      disabled?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    dateCreated: string,
    dateCompleted?: string | null,
    timeOfCall?: string | null,
    timePickedUp?: string | null,
    timePickedUpSenderName?: string | null,
    timeDroppedOff?: string | null,
    timeDroppedOffRecipientName?: string | null,
    timeCancelled?: string | null,
    timeRejected?: string | null,
    timeRiderHome?: string | null,
    requesterContact?:  {
      __typename: "AddressAndContactDetails",
      name?: string | null,
      telephoneNumber?: string | null,
      mobileNumber?: string | null,
      emailAddress?: string | null,
      ward?: string | null,
      line1?: string | null,
      line2?: string | null,
      line3?: string | null,
      town?: string | null,
      county?: string | null,
      state?: string | null,
      country?: string | null,
      postcode?: string | null,
      what3words?: string | null,
    } | null,
    pickUpLocationId?: string | null,
    dropOffLocationId?: string | null,
    establishmentLocationId?: string | null,
    pickUpLocation?:  {
      __typename: "Location",
      id: string,
      tenantId: string,
      name?: string | null,
      listed?: number | null,
      ward?: string | null,
      line1?: string | null,
      line2?: string | null,
      line3?: string | null,
      town?: string | null,
      county?: string | null,
      state?: string | null,
      country?: string | null,
      postcode?: string | null,
      what3words?: string | null,
      disabled?: number | null,
      googleMapsPlaceId?: string | null,
      archived?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      userCreatedLocationsId?: string | null,
    } | null,
    dropOffLocation?:  {
      __typename: "Location",
      id: string,
      tenantId: string,
      name?: string | null,
      listed?: number | null,
      ward?: string | null,
      line1?: string | null,
      line2?: string | null,
      line3?: string | null,
      town?: string | null,
      county?: string | null,
      state?: string | null,
      country?: string | null,
      postcode?: string | null,
      what3words?: string | null,
      disabled?: number | null,
      googleMapsPlaceId?: string | null,
      archived?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      userCreatedLocationsId?: string | null,
    } | null,
    establishmentLocation?:  {
      __typename: "Location",
      id: string,
      tenantId: string,
      name?: string | null,
      listed?: number | null,
      ward?: string | null,
      line1?: string | null,
      line2?: string | null,
      line3?: string | null,
      town?: string | null,
      county?: string | null,
      state?: string | null,
      country?: string | null,
      postcode?: string | null,
      what3words?: string | null,
      disabled?: number | null,
      googleMapsPlaceId?: string | null,
      archived?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      userCreatedLocationsId?: string | null,
    } | null,
    riderResponsibility?: string | null,
    assignees?:  {
      __typename: "ModelTaskAssigneeConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    priority?: Priority | null,
    deliverables?:  {
      __typename: "ModelDeliverableConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    comments?:  {
      __typename: "ModelCommentConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    status?: TaskStatus | null,
    isRiderUsingOwnVehicle?: number | null,
    archived?: number | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    userCreatedTasksId?: string | null,
  } | null,
};

export type CreateTaskAssigneeMutationVariables = {
  input: CreateTaskAssigneeInput,
  condition?: ModelTaskAssigneeConditionInput | null,
};

export type CreateTaskAssigneeMutation = {
  createTaskAssignee?:  {
    __typename: "TaskAssignee",
    id: string,
    tenantId: string,
    role: Role,
    task:  {
      __typename: "Task",
      id: string,
      tenantId: string,
      createdAt?: string | null,
      dateCreated: string,
      dateCompleted?: string | null,
      timeOfCall?: string | null,
      timePickedUp?: string | null,
      timePickedUpSenderName?: string | null,
      timeDroppedOff?: string | null,
      timeDroppedOffRecipientName?: string | null,
      timeCancelled?: string | null,
      timeRejected?: string | null,
      timeRiderHome?: string | null,
      pickUpLocationId?: string | null,
      dropOffLocationId?: string | null,
      establishmentLocationId?: string | null,
      riderResponsibility?: string | null,
      priority?: Priority | null,
      status?: TaskStatus | null,
      isRiderUsingOwnVehicle?: number | null,
      archived?: number | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      userCreatedTasksId?: string | null,
    },
    assignee:  {
      __typename: "User",
      id: string,
      username: string,
      cognitoId: string,
      tenantId: string,
      isPrimaryAdmin?: number | null,
      displayName: string,
      name?: string | null,
      roles: Array< Role >,
      dateOfBirth?: string | null,
      riderResponsibility?: string | null,
      disabled?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    archived?: number | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    userAssignmentsId?: string | null,
    taskAssigneesId?: string | null,
  } | null,
};

export type UpdateTaskAssigneeMutationVariables = {
  input: UpdateTaskAssigneeInput,
  condition?: ModelTaskAssigneeConditionInput | null,
};

export type UpdateTaskAssigneeMutation = {
  updateTaskAssignee?:  {
    __typename: "TaskAssignee",
    id: string,
    tenantId: string,
    role: Role,
    task:  {
      __typename: "Task",
      id: string,
      tenantId: string,
      createdAt?: string | null,
      dateCreated: string,
      dateCompleted?: string | null,
      timeOfCall?: string | null,
      timePickedUp?: string | null,
      timePickedUpSenderName?: string | null,
      timeDroppedOff?: string | null,
      timeDroppedOffRecipientName?: string | null,
      timeCancelled?: string | null,
      timeRejected?: string | null,
      timeRiderHome?: string | null,
      pickUpLocationId?: string | null,
      dropOffLocationId?: string | null,
      establishmentLocationId?: string | null,
      riderResponsibility?: string | null,
      priority?: Priority | null,
      status?: TaskStatus | null,
      isRiderUsingOwnVehicle?: number | null,
      archived?: number | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      userCreatedTasksId?: string | null,
    },
    assignee:  {
      __typename: "User",
      id: string,
      username: string,
      cognitoId: string,
      tenantId: string,
      isPrimaryAdmin?: number | null,
      displayName: string,
      name?: string | null,
      roles: Array< Role >,
      dateOfBirth?: string | null,
      riderResponsibility?: string | null,
      disabled?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    archived?: number | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    userAssignmentsId?: string | null,
    taskAssigneesId?: string | null,
  } | null,
};

export type DeleteTaskAssigneeMutationVariables = {
  input: DeleteTaskAssigneeInput,
  condition?: ModelTaskAssigneeConditionInput | null,
};

export type DeleteTaskAssigneeMutation = {
  deleteTaskAssignee?:  {
    __typename: "TaskAssignee",
    id: string,
    tenantId: string,
    role: Role,
    task:  {
      __typename: "Task",
      id: string,
      tenantId: string,
      createdAt?: string | null,
      dateCreated: string,
      dateCompleted?: string | null,
      timeOfCall?: string | null,
      timePickedUp?: string | null,
      timePickedUpSenderName?: string | null,
      timeDroppedOff?: string | null,
      timeDroppedOffRecipientName?: string | null,
      timeCancelled?: string | null,
      timeRejected?: string | null,
      timeRiderHome?: string | null,
      pickUpLocationId?: string | null,
      dropOffLocationId?: string | null,
      establishmentLocationId?: string | null,
      riderResponsibility?: string | null,
      priority?: Priority | null,
      status?: TaskStatus | null,
      isRiderUsingOwnVehicle?: number | null,
      archived?: number | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      userCreatedTasksId?: string | null,
    },
    assignee:  {
      __typename: "User",
      id: string,
      username: string,
      cognitoId: string,
      tenantId: string,
      isPrimaryAdmin?: number | null,
      displayName: string,
      name?: string | null,
      roles: Array< Role >,
      dateOfBirth?: string | null,
      riderResponsibility?: string | null,
      disabled?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    archived?: number | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    userAssignmentsId?: string | null,
    taskAssigneesId?: string | null,
  } | null,
};

export type CreateScheduledTaskMutationVariables = {
  input: CreateScheduledTaskInput,
  condition?: ModelScheduledTaskConditionInput | null,
};

export type CreateScheduledTaskMutation = {
  createScheduledTask?:  {
    __typename: "ScheduledTask",
    id: string,
    tenantId: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      username: string,
      cognitoId: string,
      tenantId: string,
      isPrimaryAdmin?: number | null,
      displayName: string,
      name?: string | null,
      roles: Array< Role >,
      dateOfBirth?: string | null,
      riderResponsibility?: string | null,
      disabled?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    requesterContact?:  {
      __typename: "AddressAndContactDetails",
      name?: string | null,
      telephoneNumber?: string | null,
      mobileNumber?: string | null,
      emailAddress?: string | null,
      ward?: string | null,
      line1?: string | null,
      line2?: string | null,
      line3?: string | null,
      town?: string | null,
      county?: string | null,
      state?: string | null,
      country?: string | null,
      postcode?: string | null,
      what3words?: string | null,
    } | null,
    cronExpression: string,
    pickUpLocationId?: string | null,
    dropOffLocationId?: string | null,
    establishmentLocationId?: string | null,
    pickUpLocation?:  {
      __typename: "Location",
      id: string,
      tenantId: string,
      name?: string | null,
      listed?: number | null,
      ward?: string | null,
      line1?: string | null,
      line2?: string | null,
      line3?: string | null,
      town?: string | null,
      county?: string | null,
      state?: string | null,
      country?: string | null,
      postcode?: string | null,
      what3words?: string | null,
      disabled?: number | null,
      googleMapsPlaceId?: string | null,
      archived?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      userCreatedLocationsId?: string | null,
    } | null,
    dropOffLocation?:  {
      __typename: "Location",
      id: string,
      tenantId: string,
      name?: string | null,
      listed?: number | null,
      ward?: string | null,
      line1?: string | null,
      line2?: string | null,
      line3?: string | null,
      town?: string | null,
      county?: string | null,
      state?: string | null,
      country?: string | null,
      postcode?: string | null,
      what3words?: string | null,
      disabled?: number | null,
      googleMapsPlaceId?: string | null,
      archived?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      userCreatedLocationsId?: string | null,
    } | null,
    establishmentLocation?:  {
      __typename: "Location",
      id: string,
      tenantId: string,
      name?: string | null,
      listed?: number | null,
      ward?: string | null,
      line1?: string | null,
      line2?: string | null,
      line3?: string | null,
      town?: string | null,
      county?: string | null,
      state?: string | null,
      country?: string | null,
      postcode?: string | null,
      what3words?: string | null,
      disabled?: number | null,
      googleMapsPlaceId?: string | null,
      archived?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      userCreatedLocationsId?: string | null,
    } | null,
    priority?: Priority | null,
    deliverables?:  {
      __typename: "ModelDeliverableConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    disabled?: number | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    userCreatedScheduledTasksId?: string | null,
  } | null,
};

export type UpdateScheduledTaskMutationVariables = {
  input: UpdateScheduledTaskInput,
  condition?: ModelScheduledTaskConditionInput | null,
};

export type UpdateScheduledTaskMutation = {
  updateScheduledTask?:  {
    __typename: "ScheduledTask",
    id: string,
    tenantId: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      username: string,
      cognitoId: string,
      tenantId: string,
      isPrimaryAdmin?: number | null,
      displayName: string,
      name?: string | null,
      roles: Array< Role >,
      dateOfBirth?: string | null,
      riderResponsibility?: string | null,
      disabled?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    requesterContact?:  {
      __typename: "AddressAndContactDetails",
      name?: string | null,
      telephoneNumber?: string | null,
      mobileNumber?: string | null,
      emailAddress?: string | null,
      ward?: string | null,
      line1?: string | null,
      line2?: string | null,
      line3?: string | null,
      town?: string | null,
      county?: string | null,
      state?: string | null,
      country?: string | null,
      postcode?: string | null,
      what3words?: string | null,
    } | null,
    cronExpression: string,
    pickUpLocationId?: string | null,
    dropOffLocationId?: string | null,
    establishmentLocationId?: string | null,
    pickUpLocation?:  {
      __typename: "Location",
      id: string,
      tenantId: string,
      name?: string | null,
      listed?: number | null,
      ward?: string | null,
      line1?: string | null,
      line2?: string | null,
      line3?: string | null,
      town?: string | null,
      county?: string | null,
      state?: string | null,
      country?: string | null,
      postcode?: string | null,
      what3words?: string | null,
      disabled?: number | null,
      googleMapsPlaceId?: string | null,
      archived?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      userCreatedLocationsId?: string | null,
    } | null,
    dropOffLocation?:  {
      __typename: "Location",
      id: string,
      tenantId: string,
      name?: string | null,
      listed?: number | null,
      ward?: string | null,
      line1?: string | null,
      line2?: string | null,
      line3?: string | null,
      town?: string | null,
      county?: string | null,
      state?: string | null,
      country?: string | null,
      postcode?: string | null,
      what3words?: string | null,
      disabled?: number | null,
      googleMapsPlaceId?: string | null,
      archived?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      userCreatedLocationsId?: string | null,
    } | null,
    establishmentLocation?:  {
      __typename: "Location",
      id: string,
      tenantId: string,
      name?: string | null,
      listed?: number | null,
      ward?: string | null,
      line1?: string | null,
      line2?: string | null,
      line3?: string | null,
      town?: string | null,
      county?: string | null,
      state?: string | null,
      country?: string | null,
      postcode?: string | null,
      what3words?: string | null,
      disabled?: number | null,
      googleMapsPlaceId?: string | null,
      archived?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      userCreatedLocationsId?: string | null,
    } | null,
    priority?: Priority | null,
    deliverables?:  {
      __typename: "ModelDeliverableConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    disabled?: number | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    userCreatedScheduledTasksId?: string | null,
  } | null,
};

export type DeleteScheduledTaskMutationVariables = {
  input: DeleteScheduledTaskInput,
  condition?: ModelScheduledTaskConditionInput | null,
};

export type DeleteScheduledTaskMutation = {
  deleteScheduledTask?:  {
    __typename: "ScheduledTask",
    id: string,
    tenantId: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      username: string,
      cognitoId: string,
      tenantId: string,
      isPrimaryAdmin?: number | null,
      displayName: string,
      name?: string | null,
      roles: Array< Role >,
      dateOfBirth?: string | null,
      riderResponsibility?: string | null,
      disabled?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    requesterContact?:  {
      __typename: "AddressAndContactDetails",
      name?: string | null,
      telephoneNumber?: string | null,
      mobileNumber?: string | null,
      emailAddress?: string | null,
      ward?: string | null,
      line1?: string | null,
      line2?: string | null,
      line3?: string | null,
      town?: string | null,
      county?: string | null,
      state?: string | null,
      country?: string | null,
      postcode?: string | null,
      what3words?: string | null,
    } | null,
    cronExpression: string,
    pickUpLocationId?: string | null,
    dropOffLocationId?: string | null,
    establishmentLocationId?: string | null,
    pickUpLocation?:  {
      __typename: "Location",
      id: string,
      tenantId: string,
      name?: string | null,
      listed?: number | null,
      ward?: string | null,
      line1?: string | null,
      line2?: string | null,
      line3?: string | null,
      town?: string | null,
      county?: string | null,
      state?: string | null,
      country?: string | null,
      postcode?: string | null,
      what3words?: string | null,
      disabled?: number | null,
      googleMapsPlaceId?: string | null,
      archived?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      userCreatedLocationsId?: string | null,
    } | null,
    dropOffLocation?:  {
      __typename: "Location",
      id: string,
      tenantId: string,
      name?: string | null,
      listed?: number | null,
      ward?: string | null,
      line1?: string | null,
      line2?: string | null,
      line3?: string | null,
      town?: string | null,
      county?: string | null,
      state?: string | null,
      country?: string | null,
      postcode?: string | null,
      what3words?: string | null,
      disabled?: number | null,
      googleMapsPlaceId?: string | null,
      archived?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      userCreatedLocationsId?: string | null,
    } | null,
    establishmentLocation?:  {
      __typename: "Location",
      id: string,
      tenantId: string,
      name?: string | null,
      listed?: number | null,
      ward?: string | null,
      line1?: string | null,
      line2?: string | null,
      line3?: string | null,
      town?: string | null,
      county?: string | null,
      state?: string | null,
      country?: string | null,
      postcode?: string | null,
      what3words?: string | null,
      disabled?: number | null,
      googleMapsPlaceId?: string | null,
      archived?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      userCreatedLocationsId?: string | null,
    } | null,
    priority?: Priority | null,
    deliverables?:  {
      __typename: "ModelDeliverableConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    disabled?: number | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    userCreatedScheduledTasksId?: string | null,
  } | null,
};

export type CreateCommentMutationVariables = {
  input: CreateCommentInput,
  condition?: ModelCommentConditionInput | null,
};

export type CreateCommentMutation = {
  createComment?:  {
    __typename: "Comment",
    id: string,
    parentId?: string | null,
    owner?: string | null,
    tenantId: string,
    body?: string | null,
    author?:  {
      __typename: "User",
      id: string,
      username: string,
      cognitoId: string,
      tenantId: string,
      isPrimaryAdmin?: number | null,
      displayName: string,
      name?: string | null,
      roles: Array< Role >,
      dateOfBirth?: string | null,
      riderResponsibility?: string | null,
      disabled?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    visibility?: CommentVisibility | null,
    archived?: number | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    userCommentsId?: string | null,
  } | null,
};

export type UpdateCommentMutationVariables = {
  input: UpdateCommentInput,
  condition?: ModelCommentConditionInput | null,
};

export type UpdateCommentMutation = {
  updateComment?:  {
    __typename: "Comment",
    id: string,
    parentId?: string | null,
    owner?: string | null,
    tenantId: string,
    body?: string | null,
    author?:  {
      __typename: "User",
      id: string,
      username: string,
      cognitoId: string,
      tenantId: string,
      isPrimaryAdmin?: number | null,
      displayName: string,
      name?: string | null,
      roles: Array< Role >,
      dateOfBirth?: string | null,
      riderResponsibility?: string | null,
      disabled?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    visibility?: CommentVisibility | null,
    archived?: number | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    userCommentsId?: string | null,
  } | null,
};

export type DeleteCommentMutationVariables = {
  input: DeleteCommentInput,
  condition?: ModelCommentConditionInput | null,
};

export type DeleteCommentMutation = {
  deleteComment?:  {
    __typename: "Comment",
    id: string,
    parentId?: string | null,
    owner?: string | null,
    tenantId: string,
    body?: string | null,
    author?:  {
      __typename: "User",
      id: string,
      username: string,
      cognitoId: string,
      tenantId: string,
      isPrimaryAdmin?: number | null,
      displayName: string,
      name?: string | null,
      roles: Array< Role >,
      dateOfBirth?: string | null,
      riderResponsibility?: string | null,
      disabled?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    visibility?: CommentVisibility | null,
    archived?: number | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    userCommentsId?: string | null,
  } | null,
};

export type CreateDeliverableTypeMutationVariables = {
  input: CreateDeliverableTypeInput,
  condition?: ModelDeliverableTypeConditionInput | null,
};

export type CreateDeliverableTypeMutation = {
  createDeliverableType?:  {
    __typename: "DeliverableType",
    id: string,
    label: string,
    tenantId: string,
    icon?: DeliverableTypeIcon | null,
    defaultUnit?: DeliverableUnit | null,
    deliverables?:  {
      __typename: "ModelDeliverableConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    tags?: Array< string | null > | null,
    disabled?: number | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateDeliverableTypeMutationVariables = {
  input: UpdateDeliverableTypeInput,
  condition?: ModelDeliverableTypeConditionInput | null,
};

export type UpdateDeliverableTypeMutation = {
  updateDeliverableType?:  {
    __typename: "DeliverableType",
    id: string,
    label: string,
    tenantId: string,
    icon?: DeliverableTypeIcon | null,
    defaultUnit?: DeliverableUnit | null,
    deliverables?:  {
      __typename: "ModelDeliverableConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    tags?: Array< string | null > | null,
    disabled?: number | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteDeliverableTypeMutationVariables = {
  input: DeleteDeliverableTypeInput,
  condition?: ModelDeliverableTypeConditionInput | null,
};

export type DeleteDeliverableTypeMutation = {
  deleteDeliverableType?:  {
    __typename: "DeliverableType",
    id: string,
    label: string,
    tenantId: string,
    icon?: DeliverableTypeIcon | null,
    defaultUnit?: DeliverableUnit | null,
    deliverables?:  {
      __typename: "ModelDeliverableConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    tags?: Array< string | null > | null,
    disabled?: number | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateDeliverableMutationVariables = {
  input: CreateDeliverableInput,
  condition?: ModelDeliverableConditionInput | null,
};

export type CreateDeliverableMutation = {
  createDeliverable?:  {
    __typename: "Deliverable",
    id: string,
    tenantId: string,
    deliverableType?:  {
      __typename: "DeliverableType",
      id: string,
      label: string,
      tenantId: string,
      icon?: DeliverableTypeIcon | null,
      defaultUnit?: DeliverableUnit | null,
      tags?: Array< string | null > | null,
      disabled?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    task?:  {
      __typename: "Task",
      id: string,
      tenantId: string,
      createdAt?: string | null,
      dateCreated: string,
      dateCompleted?: string | null,
      timeOfCall?: string | null,
      timePickedUp?: string | null,
      timePickedUpSenderName?: string | null,
      timeDroppedOff?: string | null,
      timeDroppedOffRecipientName?: string | null,
      timeCancelled?: string | null,
      timeRejected?: string | null,
      timeRiderHome?: string | null,
      pickUpLocationId?: string | null,
      dropOffLocationId?: string | null,
      establishmentLocationId?: string | null,
      riderResponsibility?: string | null,
      priority?: Priority | null,
      status?: TaskStatus | null,
      isRiderUsingOwnVehicle?: number | null,
      archived?: number | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      userCreatedTasksId?: string | null,
    } | null,
    scheduledTask?:  {
      __typename: "ScheduledTask",
      id: string,
      tenantId: string,
      cronExpression: string,
      pickUpLocationId?: string | null,
      dropOffLocationId?: string | null,
      establishmentLocationId?: string | null,
      priority?: Priority | null,
      disabled?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      userCreatedScheduledTasksId?: string | null,
    } | null,
    count?: number | null,
    unit?: DeliverableUnit | null,
    orderInGrid?: number | null,
    comments?:  {
      __typename: "ModelCommentConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    archived?: number | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    taskDeliverablesId?: string | null,
    scheduledTaskDeliverablesId?: string | null,
    deliverableTypeDeliverablesId?: string | null,
  } | null,
};

export type UpdateDeliverableMutationVariables = {
  input: UpdateDeliverableInput,
  condition?: ModelDeliverableConditionInput | null,
};

export type UpdateDeliverableMutation = {
  updateDeliverable?:  {
    __typename: "Deliverable",
    id: string,
    tenantId: string,
    deliverableType?:  {
      __typename: "DeliverableType",
      id: string,
      label: string,
      tenantId: string,
      icon?: DeliverableTypeIcon | null,
      defaultUnit?: DeliverableUnit | null,
      tags?: Array< string | null > | null,
      disabled?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    task?:  {
      __typename: "Task",
      id: string,
      tenantId: string,
      createdAt?: string | null,
      dateCreated: string,
      dateCompleted?: string | null,
      timeOfCall?: string | null,
      timePickedUp?: string | null,
      timePickedUpSenderName?: string | null,
      timeDroppedOff?: string | null,
      timeDroppedOffRecipientName?: string | null,
      timeCancelled?: string | null,
      timeRejected?: string | null,
      timeRiderHome?: string | null,
      pickUpLocationId?: string | null,
      dropOffLocationId?: string | null,
      establishmentLocationId?: string | null,
      riderResponsibility?: string | null,
      priority?: Priority | null,
      status?: TaskStatus | null,
      isRiderUsingOwnVehicle?: number | null,
      archived?: number | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      userCreatedTasksId?: string | null,
    } | null,
    scheduledTask?:  {
      __typename: "ScheduledTask",
      id: string,
      tenantId: string,
      cronExpression: string,
      pickUpLocationId?: string | null,
      dropOffLocationId?: string | null,
      establishmentLocationId?: string | null,
      priority?: Priority | null,
      disabled?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      userCreatedScheduledTasksId?: string | null,
    } | null,
    count?: number | null,
    unit?: DeliverableUnit | null,
    orderInGrid?: number | null,
    comments?:  {
      __typename: "ModelCommentConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    archived?: number | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    taskDeliverablesId?: string | null,
    scheduledTaskDeliverablesId?: string | null,
    deliverableTypeDeliverablesId?: string | null,
  } | null,
};

export type DeleteDeliverableMutationVariables = {
  input: DeleteDeliverableInput,
  condition?: ModelDeliverableConditionInput | null,
};

export type DeleteDeliverableMutation = {
  deleteDeliverable?:  {
    __typename: "Deliverable",
    id: string,
    tenantId: string,
    deliverableType?:  {
      __typename: "DeliverableType",
      id: string,
      label: string,
      tenantId: string,
      icon?: DeliverableTypeIcon | null,
      defaultUnit?: DeliverableUnit | null,
      tags?: Array< string | null > | null,
      disabled?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    task?:  {
      __typename: "Task",
      id: string,
      tenantId: string,
      createdAt?: string | null,
      dateCreated: string,
      dateCompleted?: string | null,
      timeOfCall?: string | null,
      timePickedUp?: string | null,
      timePickedUpSenderName?: string | null,
      timeDroppedOff?: string | null,
      timeDroppedOffRecipientName?: string | null,
      timeCancelled?: string | null,
      timeRejected?: string | null,
      timeRiderHome?: string | null,
      pickUpLocationId?: string | null,
      dropOffLocationId?: string | null,
      establishmentLocationId?: string | null,
      riderResponsibility?: string | null,
      priority?: Priority | null,
      status?: TaskStatus | null,
      isRiderUsingOwnVehicle?: number | null,
      archived?: number | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      userCreatedTasksId?: string | null,
    } | null,
    scheduledTask?:  {
      __typename: "ScheduledTask",
      id: string,
      tenantId: string,
      cronExpression: string,
      pickUpLocationId?: string | null,
      dropOffLocationId?: string | null,
      establishmentLocationId?: string | null,
      priority?: Priority | null,
      disabled?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      userCreatedScheduledTasksId?: string | null,
    } | null,
    count?: number | null,
    unit?: DeliverableUnit | null,
    orderInGrid?: number | null,
    comments?:  {
      __typename: "ModelCommentConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    archived?: number | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    taskDeliverablesId?: string | null,
    scheduledTaskDeliverablesId?: string | null,
    deliverableTypeDeliverablesId?: string | null,
  } | null,
};

export type CreateRiderResponsibilityMutationVariables = {
  input: CreateRiderResponsibilityInput,
  condition?: ModelRiderResponsibilityConditionInput | null,
};

export type CreateRiderResponsibilityMutation = {
  createRiderResponsibility?:  {
    __typename: "RiderResponsibility",
    id: string,
    tenantId: string,
    label: string,
    disabled?: number | null,
    possibleUsers?:  {
      __typename: "ModelPossibleRiderResponsibilitiesConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateRiderResponsibilityMutationVariables = {
  input: UpdateRiderResponsibilityInput,
  condition?: ModelRiderResponsibilityConditionInput | null,
};

export type UpdateRiderResponsibilityMutation = {
  updateRiderResponsibility?:  {
    __typename: "RiderResponsibility",
    id: string,
    tenantId: string,
    label: string,
    disabled?: number | null,
    possibleUsers?:  {
      __typename: "ModelPossibleRiderResponsibilitiesConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteRiderResponsibilityMutationVariables = {
  input: DeleteRiderResponsibilityInput,
  condition?: ModelRiderResponsibilityConditionInput | null,
};

export type DeleteRiderResponsibilityMutation = {
  deleteRiderResponsibility?:  {
    __typename: "RiderResponsibility",
    id: string,
    tenantId: string,
    label: string,
    disabled?: number | null,
    possibleUsers?:  {
      __typename: "ModelPossibleRiderResponsibilitiesConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type RegisterUserMutationVariables = {
  name?: string | null,
  email?: string | null,
  tenantId?: string | null,
  roles?: Array< Role | null > | null,
};

export type RegisterUserMutation = {
  registerUser?:  {
    __typename: "User",
    id: string,
    username: string,
    cognitoId: string,
    tenantId: string,
    isPrimaryAdmin?: number | null,
    contact?:  {
      __typename: "AddressAndContactDetails",
      name?: string | null,
      telephoneNumber?: string | null,
      mobileNumber?: string | null,
      emailAddress?: string | null,
      ward?: string | null,
      line1?: string | null,
      line2?: string | null,
      line3?: string | null,
      town?: string | null,
      county?: string | null,
      state?: string | null,
      country?: string | null,
      postcode?: string | null,
      what3words?: string | null,
    } | null,
    displayName: string,
    name?: string | null,
    roles: Array< Role >,
    dateOfBirth?: string | null,
    riderResponsibility?: string | null,
    possibleRiderResponsibilities?:  {
      __typename: "ModelPossibleRiderResponsibilitiesConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    profilePicture?:  {
      __typename: "S3Object",
      bucket: string,
      key: string,
      region: string,
    } | null,
    comments?:  {
      __typename: "ModelCommentConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    assignments?:  {
      __typename: "ModelTaskAssigneeConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    vehicleAssignments?:  {
      __typename: "ModelVehicleAssignmentConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdTasks?:  {
      __typename: "ModelTaskConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdLocations?:  {
      __typename: "ModelLocationConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdVehicles?:  {
      __typename: "ModelVehicleConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdScheduledTasks?:  {
      __typename: "ModelScheduledTaskConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    disabled?: number | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type RegisterTenantMutationVariables = {
  name?: string | null,
  emailAddress?: string | null,
  tenantName?: string | null,
};

export type RegisterTenantMutation = {
  registerTenant?:  {
    __typename: "Tenant",
    id: string,
    name: string,
    referenceIdentifier: string,
    admin:  {
      __typename: "User",
      id: string,
      username: string,
      cognitoId: string,
      tenantId: string,
      isPrimaryAdmin?: number | null,
      displayName: string,
      name?: string | null,
      roles: Array< Role >,
      dateOfBirth?: string | null,
      riderResponsibility?: string | null,
      disabled?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    tenantAdminId: string,
  } | null,
};

export type UpdateUserRolesMutationVariables = {
  userId?: string | null,
  roles?: Array< Role | null > | null,
};

export type UpdateUserRolesMutation = {
  updateUserRoles?:  {
    __typename: "User",
    id: string,
    username: string,
    cognitoId: string,
    tenantId: string,
    isPrimaryAdmin?: number | null,
    contact?:  {
      __typename: "AddressAndContactDetails",
      name?: string | null,
      telephoneNumber?: string | null,
      mobileNumber?: string | null,
      emailAddress?: string | null,
      ward?: string | null,
      line1?: string | null,
      line2?: string | null,
      line3?: string | null,
      town?: string | null,
      county?: string | null,
      state?: string | null,
      country?: string | null,
      postcode?: string | null,
      what3words?: string | null,
    } | null,
    displayName: string,
    name?: string | null,
    roles: Array< Role >,
    dateOfBirth?: string | null,
    riderResponsibility?: string | null,
    possibleRiderResponsibilities?:  {
      __typename: "ModelPossibleRiderResponsibilitiesConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    profilePicture?:  {
      __typename: "S3Object",
      bucket: string,
      key: string,
      region: string,
    } | null,
    comments?:  {
      __typename: "ModelCommentConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    assignments?:  {
      __typename: "ModelTaskAssigneeConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    vehicleAssignments?:  {
      __typename: "ModelVehicleAssignmentConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdTasks?:  {
      __typename: "ModelTaskConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdLocations?:  {
      __typename: "ModelLocationConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdVehicles?:  {
      __typename: "ModelVehicleConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdScheduledTasks?:  {
      __typename: "ModelScheduledTaskConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    disabled?: number | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DisableUserMutationVariables = {
  userId?: string | null,
};

export type DisableUserMutation = {
  disableUser?:  {
    __typename: "User",
    id: string,
    username: string,
    cognitoId: string,
    tenantId: string,
    isPrimaryAdmin?: number | null,
    contact?:  {
      __typename: "AddressAndContactDetails",
      name?: string | null,
      telephoneNumber?: string | null,
      mobileNumber?: string | null,
      emailAddress?: string | null,
      ward?: string | null,
      line1?: string | null,
      line2?: string | null,
      line3?: string | null,
      town?: string | null,
      county?: string | null,
      state?: string | null,
      country?: string | null,
      postcode?: string | null,
      what3words?: string | null,
    } | null,
    displayName: string,
    name?: string | null,
    roles: Array< Role >,
    dateOfBirth?: string | null,
    riderResponsibility?: string | null,
    possibleRiderResponsibilities?:  {
      __typename: "ModelPossibleRiderResponsibilitiesConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    profilePicture?:  {
      __typename: "S3Object",
      bucket: string,
      key: string,
      region: string,
    } | null,
    comments?:  {
      __typename: "ModelCommentConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    assignments?:  {
      __typename: "ModelTaskAssigneeConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    vehicleAssignments?:  {
      __typename: "ModelVehicleAssignmentConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdTasks?:  {
      __typename: "ModelTaskConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdLocations?:  {
      __typename: "ModelLocationConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdVehicles?:  {
      __typename: "ModelVehicleConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdScheduledTasks?:  {
      __typename: "ModelScheduledTaskConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    disabled?: number | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type EnableUserMutationVariables = {
  userId?: string | null,
};

export type EnableUserMutation = {
  enableUser?:  {
    __typename: "User",
    id: string,
    username: string,
    cognitoId: string,
    tenantId: string,
    isPrimaryAdmin?: number | null,
    contact?:  {
      __typename: "AddressAndContactDetails",
      name?: string | null,
      telephoneNumber?: string | null,
      mobileNumber?: string | null,
      emailAddress?: string | null,
      ward?: string | null,
      line1?: string | null,
      line2?: string | null,
      line3?: string | null,
      town?: string | null,
      county?: string | null,
      state?: string | null,
      country?: string | null,
      postcode?: string | null,
      what3words?: string | null,
    } | null,
    displayName: string,
    name?: string | null,
    roles: Array< Role >,
    dateOfBirth?: string | null,
    riderResponsibility?: string | null,
    possibleRiderResponsibilities?:  {
      __typename: "ModelPossibleRiderResponsibilitiesConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    profilePicture?:  {
      __typename: "S3Object",
      bucket: string,
      key: string,
      region: string,
    } | null,
    comments?:  {
      __typename: "ModelCommentConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    assignments?:  {
      __typename: "ModelTaskAssigneeConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    vehicleAssignments?:  {
      __typename: "ModelVehicleAssignmentConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdTasks?:  {
      __typename: "ModelTaskConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdLocations?:  {
      __typename: "ModelLocationConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdVehicles?:  {
      __typename: "ModelVehicleConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdScheduledTasks?:  {
      __typename: "ModelScheduledTaskConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    disabled?: number | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateUserEmailMutationVariables = {
  userId?: string | null,
  emailAddress?: string | null,
};

export type UpdateUserEmailMutation = {
  updateUserEmail?:  {
    __typename: "User",
    id: string,
    username: string,
    cognitoId: string,
    tenantId: string,
    isPrimaryAdmin?: number | null,
    contact?:  {
      __typename: "AddressAndContactDetails",
      name?: string | null,
      telephoneNumber?: string | null,
      mobileNumber?: string | null,
      emailAddress?: string | null,
      ward?: string | null,
      line1?: string | null,
      line2?: string | null,
      line3?: string | null,
      town?: string | null,
      county?: string | null,
      state?: string | null,
      country?: string | null,
      postcode?: string | null,
      what3words?: string | null,
    } | null,
    displayName: string,
    name?: string | null,
    roles: Array< Role >,
    dateOfBirth?: string | null,
    riderResponsibility?: string | null,
    possibleRiderResponsibilities?:  {
      __typename: "ModelPossibleRiderResponsibilitiesConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    profilePicture?:  {
      __typename: "S3Object",
      bucket: string,
      key: string,
      region: string,
    } | null,
    comments?:  {
      __typename: "ModelCommentConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    assignments?:  {
      __typename: "ModelTaskAssigneeConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    vehicleAssignments?:  {
      __typename: "ModelVehicleAssignmentConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdTasks?:  {
      __typename: "ModelTaskConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdLocations?:  {
      __typename: "ModelLocationConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdVehicles?:  {
      __typename: "ModelVehicleConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdScheduledTasks?:  {
      __typename: "ModelScheduledTaskConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    disabled?: number | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ResetUserPasswordMutationVariables = {
  userId?: string | null,
};

export type ResetUserPasswordMutation = {
  resetUserPassword?:  {
    __typename: "SendFeedback",
    successState?: boolean | null,
  } | null,
};

export type GetTenantQueryVariables = {
  id: string,
};

export type GetTenantQuery = {
  getTenant?:  {
    __typename: "Tenant",
    id: string,
    name: string,
    referenceIdentifier: string,
    admin:  {
      __typename: "User",
      id: string,
      username: string,
      cognitoId: string,
      tenantId: string,
      isPrimaryAdmin?: number | null,
      displayName: string,
      name?: string | null,
      roles: Array< Role >,
      dateOfBirth?: string | null,
      riderResponsibility?: string | null,
      disabled?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    tenantAdminId: string,
  } | null,
};

export type ListTenantsQueryVariables = {
  filter?: ModelTenantFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListTenantsQuery = {
  listTenants?:  {
    __typename: "ModelTenantConnection",
    items:  Array< {
      __typename: "Tenant",
      id: string,
      name: string,
      referenceIdentifier: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      tenantAdminId: string,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncTenantsQueryVariables = {
  filter?: ModelTenantFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncTenantsQuery = {
  syncTenants?:  {
    __typename: "ModelTenantConnection",
    items:  Array< {
      __typename: "Tenant",
      id: string,
      name: string,
      referenceIdentifier: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      tenantAdminId: string,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetTenantByTenantNameQueryVariables = {
  name: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelTenantFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type GetTenantByTenantNameQuery = {
  getTenantByTenantName?:  {
    __typename: "ModelTenantConnection",
    items:  Array< {
      __typename: "Tenant",
      id: string,
      name: string,
      referenceIdentifier: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      tenantAdminId: string,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetUserQueryVariables = {
  id: string,
};

export type GetUserQuery = {
  getUser?:  {
    __typename: "User",
    id: string,
    username: string,
    cognitoId: string,
    tenantId: string,
    isPrimaryAdmin?: number | null,
    contact?:  {
      __typename: "AddressAndContactDetails",
      name?: string | null,
      telephoneNumber?: string | null,
      mobileNumber?: string | null,
      emailAddress?: string | null,
      ward?: string | null,
      line1?: string | null,
      line2?: string | null,
      line3?: string | null,
      town?: string | null,
      county?: string | null,
      state?: string | null,
      country?: string | null,
      postcode?: string | null,
      what3words?: string | null,
    } | null,
    displayName: string,
    name?: string | null,
    roles: Array< Role >,
    dateOfBirth?: string | null,
    riderResponsibility?: string | null,
    possibleRiderResponsibilities?:  {
      __typename: "ModelPossibleRiderResponsibilitiesConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    profilePicture?:  {
      __typename: "S3Object",
      bucket: string,
      key: string,
      region: string,
    } | null,
    comments?:  {
      __typename: "ModelCommentConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    assignments?:  {
      __typename: "ModelTaskAssigneeConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    vehicleAssignments?:  {
      __typename: "ModelVehicleAssignmentConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdTasks?:  {
      __typename: "ModelTaskConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdLocations?:  {
      __typename: "ModelLocationConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdVehicles?:  {
      __typename: "ModelVehicleConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdScheduledTasks?:  {
      __typename: "ModelScheduledTaskConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    disabled?: number | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListUsersQueryVariables = {
  filter?: ModelUserFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListUsersQuery = {
  listUsers?:  {
    __typename: "ModelUserConnection",
    items:  Array< {
      __typename: "User",
      id: string,
      username: string,
      cognitoId: string,
      tenantId: string,
      isPrimaryAdmin?: number | null,
      displayName: string,
      name?: string | null,
      roles: Array< Role >,
      dateOfBirth?: string | null,
      riderResponsibility?: string | null,
      disabled?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncUsersQueryVariables = {
  filter?: ModelUserFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncUsersQuery = {
  syncUsers?:  {
    __typename: "ModelUserConnection",
    items:  Array< {
      __typename: "User",
      id: string,
      username: string,
      cognitoId: string,
      tenantId: string,
      isPrimaryAdmin?: number | null,
      displayName: string,
      name?: string | null,
      roles: Array< Role >,
      dateOfBirth?: string | null,
      riderResponsibility?: string | null,
      disabled?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetUserByCognitoIdQueryVariables = {
  cognitoId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelUserFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type GetUserByCognitoIdQuery = {
  getUserByCognitoId?:  {
    __typename: "ModelUserConnection",
    items:  Array< {
      __typename: "User",
      id: string,
      username: string,
      cognitoId: string,
      tenantId: string,
      isPrimaryAdmin?: number | null,
      displayName: string,
      name?: string | null,
      roles: Array< Role >,
      dateOfBirth?: string | null,
      riderResponsibility?: string | null,
      disabled?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetPossibleRiderResponsibilitiesQueryVariables = {
  id: string,
};

export type GetPossibleRiderResponsibilitiesQuery = {
  getPossibleRiderResponsibilities?:  {
    __typename: "PossibleRiderResponsibilities",
    id: string,
    tenantId: string,
    user:  {
      __typename: "User",
      id: string,
      username: string,
      cognitoId: string,
      tenantId: string,
      isPrimaryAdmin?: number | null,
      displayName: string,
      name?: string | null,
      roles: Array< Role >,
      dateOfBirth?: string | null,
      riderResponsibility?: string | null,
      disabled?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    riderResponsibility:  {
      __typename: "RiderResponsibility",
      id: string,
      tenantId: string,
      label: string,
      disabled?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    userPossibleRiderResponsibilitiesId?: string | null,
    riderResponsibilityPossibleUsersId?: string | null,
  } | null,
};

export type ListPossibleRiderResponsibilitiesQueryVariables = {
  filter?: ModelPossibleRiderResponsibilitiesFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListPossibleRiderResponsibilitiesQuery = {
  listPossibleRiderResponsibilities?:  {
    __typename: "ModelPossibleRiderResponsibilitiesConnection",
    items:  Array< {
      __typename: "PossibleRiderResponsibilities",
      id: string,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      userPossibleRiderResponsibilitiesId?: string | null,
      riderResponsibilityPossibleUsersId?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncPossibleRiderResponsibilitiesQueryVariables = {
  filter?: ModelPossibleRiderResponsibilitiesFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncPossibleRiderResponsibilitiesQuery = {
  syncPossibleRiderResponsibilities?:  {
    __typename: "ModelPossibleRiderResponsibilitiesConnection",
    items:  Array< {
      __typename: "PossibleRiderResponsibilities",
      id: string,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      userPossibleRiderResponsibilitiesId?: string | null,
      riderResponsibilityPossibleUsersId?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetVehicleQueryVariables = {
  id: string,
};

export type GetVehicleQuery = {
  getVehicle?:  {
    __typename: "Vehicle",
    id: string,
    tenantId: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      username: string,
      cognitoId: string,
      tenantId: string,
      isPrimaryAdmin?: number | null,
      displayName: string,
      name?: string | null,
      roles: Array< Role >,
      dateOfBirth?: string | null,
      riderResponsibility?: string | null,
      disabled?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    name?: string | null,
    manufacturer?: string | null,
    model?: string | null,
    dateOfManufacture?: string | null,
    dateOfRegistration?: string | null,
    assignments?:  {
      __typename: "ModelVehicleAssignmentConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    comments?:  {
      __typename: "ModelCommentConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    disabled?: number | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    userCreatedVehiclesId?: string | null,
  } | null,
};

export type ListVehiclesQueryVariables = {
  filter?: ModelVehicleFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListVehiclesQuery = {
  listVehicles?:  {
    __typename: "ModelVehicleConnection",
    items:  Array< {
      __typename: "Vehicle",
      id: string,
      tenantId: string,
      name?: string | null,
      manufacturer?: string | null,
      model?: string | null,
      dateOfManufacture?: string | null,
      dateOfRegistration?: string | null,
      disabled?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      userCreatedVehiclesId?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncVehiclesQueryVariables = {
  filter?: ModelVehicleFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncVehiclesQuery = {
  syncVehicles?:  {
    __typename: "ModelVehicleConnection",
    items:  Array< {
      __typename: "Vehicle",
      id: string,
      tenantId: string,
      name?: string | null,
      manufacturer?: string | null,
      model?: string | null,
      dateOfManufacture?: string | null,
      dateOfRegistration?: string | null,
      disabled?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      userCreatedVehiclesId?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetVehicleAssignmentQueryVariables = {
  id: string,
};

export type GetVehicleAssignmentQuery = {
  getVehicleAssignment?:  {
    __typename: "VehicleAssignment",
    id: string,
    tenantId: string,
    vehicle:  {
      __typename: "Vehicle",
      id: string,
      tenantId: string,
      name?: string | null,
      manufacturer?: string | null,
      model?: string | null,
      dateOfManufacture?: string | null,
      dateOfRegistration?: string | null,
      disabled?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      userCreatedVehiclesId?: string | null,
    },
    assignee:  {
      __typename: "User",
      id: string,
      username: string,
      cognitoId: string,
      tenantId: string,
      isPrimaryAdmin?: number | null,
      displayName: string,
      name?: string | null,
      roles: Array< Role >,
      dateOfBirth?: string | null,
      riderResponsibility?: string | null,
      disabled?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    userVehicleAssignmentsId?: string | null,
    vehicleAssignmentsId?: string | null,
  } | null,
};

export type ListVehicleAssignmentsQueryVariables = {
  filter?: ModelVehicleAssignmentFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListVehicleAssignmentsQuery = {
  listVehicleAssignments?:  {
    __typename: "ModelVehicleAssignmentConnection",
    items:  Array< {
      __typename: "VehicleAssignment",
      id: string,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      userVehicleAssignmentsId?: string | null,
      vehicleAssignmentsId?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncVehicleAssignmentsQueryVariables = {
  filter?: ModelVehicleAssignmentFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncVehicleAssignmentsQuery = {
  syncVehicleAssignments?:  {
    __typename: "ModelVehicleAssignmentConnection",
    items:  Array< {
      __typename: "VehicleAssignment",
      id: string,
      tenantId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      userVehicleAssignmentsId?: string | null,
      vehicleAssignmentsId?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetLocationQueryVariables = {
  id: string,
};

export type GetLocationQuery = {
  getLocation?:  {
    __typename: "Location",
    id: string,
    tenantId: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      username: string,
      cognitoId: string,
      tenantId: string,
      isPrimaryAdmin?: number | null,
      displayName: string,
      name?: string | null,
      roles: Array< Role >,
      dateOfBirth?: string | null,
      riderResponsibility?: string | null,
      disabled?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    name?: string | null,
    listed?: number | null,
    contact?:  {
      __typename: "AddressAndContactDetails",
      name?: string | null,
      telephoneNumber?: string | null,
      mobileNumber?: string | null,
      emailAddress?: string | null,
      ward?: string | null,
      line1?: string | null,
      line2?: string | null,
      line3?: string | null,
      town?: string | null,
      county?: string | null,
      state?: string | null,
      country?: string | null,
      postcode?: string | null,
      what3words?: string | null,
    } | null,
    ward?: string | null,
    line1?: string | null,
    line2?: string | null,
    line3?: string | null,
    town?: string | null,
    county?: string | null,
    state?: string | null,
    country?: string | null,
    postcode?: string | null,
    what3words?: string | null,
    tasksAsPickUp?:  {
      __typename: "ModelTaskConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    tasksAsDropOff?:  {
      __typename: "ModelTaskConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    taskAsEstablishment?:  {
      __typename: "ModelTaskConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    scheduledTasksAsPickUp?:  {
      __typename: "ModelScheduledTaskConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    scheduledTasksAsDropOff?:  {
      __typename: "ModelScheduledTaskConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    scheduledTasksAsEstablishment?:  {
      __typename: "ModelScheduledTaskConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    comments?:  {
      __typename: "ModelCommentConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    disabled?: number | null,
    googleMapsPlaceId?: string | null,
    archived?: number | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    userCreatedLocationsId?: string | null,
  } | null,
};

export type ListLocationsQueryVariables = {
  filter?: ModelLocationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListLocationsQuery = {
  listLocations?:  {
    __typename: "ModelLocationConnection",
    items:  Array< {
      __typename: "Location",
      id: string,
      tenantId: string,
      name?: string | null,
      listed?: number | null,
      ward?: string | null,
      line1?: string | null,
      line2?: string | null,
      line3?: string | null,
      town?: string | null,
      county?: string | null,
      state?: string | null,
      country?: string | null,
      postcode?: string | null,
      what3words?: string | null,
      disabled?: number | null,
      googleMapsPlaceId?: string | null,
      archived?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      userCreatedLocationsId?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncLocationsQueryVariables = {
  filter?: ModelLocationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncLocationsQuery = {
  syncLocations?:  {
    __typename: "ModelLocationConnection",
    items:  Array< {
      __typename: "Location",
      id: string,
      tenantId: string,
      name?: string | null,
      listed?: number | null,
      ward?: string | null,
      line1?: string | null,
      line2?: string | null,
      line3?: string | null,
      town?: string | null,
      county?: string | null,
      state?: string | null,
      country?: string | null,
      postcode?: string | null,
      what3words?: string | null,
      disabled?: number | null,
      googleMapsPlaceId?: string | null,
      archived?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      userCreatedLocationsId?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetTaskQueryVariables = {
  id: string,
};

export type GetTaskQuery = {
  getTask?:  {
    __typename: "Task",
    id: string,
    tenantId: string,
    createdAt?: string | null,
    createdBy?:  {
      __typename: "User",
      id: string,
      username: string,
      cognitoId: string,
      tenantId: string,
      isPrimaryAdmin?: number | null,
      displayName: string,
      name?: string | null,
      roles: Array< Role >,
      dateOfBirth?: string | null,
      riderResponsibility?: string | null,
      disabled?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    dateCreated: string,
    dateCompleted?: string | null,
    timeOfCall?: string | null,
    timePickedUp?: string | null,
    timePickedUpSenderName?: string | null,
    timeDroppedOff?: string | null,
    timeDroppedOffRecipientName?: string | null,
    timeCancelled?: string | null,
    timeRejected?: string | null,
    timeRiderHome?: string | null,
    requesterContact?:  {
      __typename: "AddressAndContactDetails",
      name?: string | null,
      telephoneNumber?: string | null,
      mobileNumber?: string | null,
      emailAddress?: string | null,
      ward?: string | null,
      line1?: string | null,
      line2?: string | null,
      line3?: string | null,
      town?: string | null,
      county?: string | null,
      state?: string | null,
      country?: string | null,
      postcode?: string | null,
      what3words?: string | null,
    } | null,
    pickUpLocationId?: string | null,
    dropOffLocationId?: string | null,
    establishmentLocationId?: string | null,
    pickUpLocation?:  {
      __typename: "Location",
      id: string,
      tenantId: string,
      name?: string | null,
      listed?: number | null,
      ward?: string | null,
      line1?: string | null,
      line2?: string | null,
      line3?: string | null,
      town?: string | null,
      county?: string | null,
      state?: string | null,
      country?: string | null,
      postcode?: string | null,
      what3words?: string | null,
      disabled?: number | null,
      googleMapsPlaceId?: string | null,
      archived?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      userCreatedLocationsId?: string | null,
    } | null,
    dropOffLocation?:  {
      __typename: "Location",
      id: string,
      tenantId: string,
      name?: string | null,
      listed?: number | null,
      ward?: string | null,
      line1?: string | null,
      line2?: string | null,
      line3?: string | null,
      town?: string | null,
      county?: string | null,
      state?: string | null,
      country?: string | null,
      postcode?: string | null,
      what3words?: string | null,
      disabled?: number | null,
      googleMapsPlaceId?: string | null,
      archived?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      userCreatedLocationsId?: string | null,
    } | null,
    establishmentLocation?:  {
      __typename: "Location",
      id: string,
      tenantId: string,
      name?: string | null,
      listed?: number | null,
      ward?: string | null,
      line1?: string | null,
      line2?: string | null,
      line3?: string | null,
      town?: string | null,
      county?: string | null,
      state?: string | null,
      country?: string | null,
      postcode?: string | null,
      what3words?: string | null,
      disabled?: number | null,
      googleMapsPlaceId?: string | null,
      archived?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      userCreatedLocationsId?: string | null,
    } | null,
    riderResponsibility?: string | null,
    assignees?:  {
      __typename: "ModelTaskAssigneeConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    priority?: Priority | null,
    deliverables?:  {
      __typename: "ModelDeliverableConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    comments?:  {
      __typename: "ModelCommentConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    status?: TaskStatus | null,
    isRiderUsingOwnVehicle?: number | null,
    archived?: number | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    userCreatedTasksId?: string | null,
  } | null,
};

export type ListTasksQueryVariables = {
  filter?: ModelTaskFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListTasksQuery = {
  listTasks?:  {
    __typename: "ModelTaskConnection",
    items:  Array< {
      __typename: "Task",
      id: string,
      tenantId: string,
      createdAt?: string | null,
      dateCreated: string,
      dateCompleted?: string | null,
      timeOfCall?: string | null,
      timePickedUp?: string | null,
      timePickedUpSenderName?: string | null,
      timeDroppedOff?: string | null,
      timeDroppedOffRecipientName?: string | null,
      timeCancelled?: string | null,
      timeRejected?: string | null,
      timeRiderHome?: string | null,
      pickUpLocationId?: string | null,
      dropOffLocationId?: string | null,
      establishmentLocationId?: string | null,
      riderResponsibility?: string | null,
      priority?: Priority | null,
      status?: TaskStatus | null,
      isRiderUsingOwnVehicle?: number | null,
      archived?: number | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      userCreatedTasksId?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncTasksQueryVariables = {
  filter?: ModelTaskFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncTasksQuery = {
  syncTasks?:  {
    __typename: "ModelTaskConnection",
    items:  Array< {
      __typename: "Task",
      id: string,
      tenantId: string,
      createdAt?: string | null,
      dateCreated: string,
      dateCompleted?: string | null,
      timeOfCall?: string | null,
      timePickedUp?: string | null,
      timePickedUpSenderName?: string | null,
      timeDroppedOff?: string | null,
      timeDroppedOffRecipientName?: string | null,
      timeCancelled?: string | null,
      timeRejected?: string | null,
      timeRiderHome?: string | null,
      pickUpLocationId?: string | null,
      dropOffLocationId?: string | null,
      establishmentLocationId?: string | null,
      riderResponsibility?: string | null,
      priority?: Priority | null,
      status?: TaskStatus | null,
      isRiderUsingOwnVehicle?: number | null,
      archived?: number | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      userCreatedTasksId?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ListTasksByTenantIdQueryVariables = {
  tenantId: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelTaskFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListTasksByTenantIdQuery = {
  listTasksByTenantId?:  {
    __typename: "ModelTaskConnection",
    items:  Array< {
      __typename: "Task",
      id: string,
      tenantId: string,
      createdAt?: string | null,
      dateCreated: string,
      dateCompleted?: string | null,
      timeOfCall?: string | null,
      timePickedUp?: string | null,
      timePickedUpSenderName?: string | null,
      timeDroppedOff?: string | null,
      timeDroppedOffRecipientName?: string | null,
      timeCancelled?: string | null,
      timeRejected?: string | null,
      timeRiderHome?: string | null,
      pickUpLocationId?: string | null,
      dropOffLocationId?: string | null,
      establishmentLocationId?: string | null,
      riderResponsibility?: string | null,
      priority?: Priority | null,
      status?: TaskStatus | null,
      isRiderUsingOwnVehicle?: number | null,
      archived?: number | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      userCreatedTasksId?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type TasksByStatusQueryVariables = {
  status: TaskStatus,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelTaskFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type TasksByStatusQuery = {
  tasksByStatus?:  {
    __typename: "ModelTaskConnection",
    items:  Array< {
      __typename: "Task",
      id: string,
      tenantId: string,
      createdAt?: string | null,
      dateCreated: string,
      dateCompleted?: string | null,
      timeOfCall?: string | null,
      timePickedUp?: string | null,
      timePickedUpSenderName?: string | null,
      timeDroppedOff?: string | null,
      timeDroppedOffRecipientName?: string | null,
      timeCancelled?: string | null,
      timeRejected?: string | null,
      timeRiderHome?: string | null,
      pickUpLocationId?: string | null,
      dropOffLocationId?: string | null,
      establishmentLocationId?: string | null,
      riderResponsibility?: string | null,
      priority?: Priority | null,
      status?: TaskStatus | null,
      isRiderUsingOwnVehicle?: number | null,
      archived?: number | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      userCreatedTasksId?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type TasksByArchivedStatusQueryVariables = {
  archived: number,
  status?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelTaskFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type TasksByArchivedStatusQuery = {
  tasksByArchivedStatus?:  {
    __typename: "ModelTaskConnection",
    items:  Array< {
      __typename: "Task",
      id: string,
      tenantId: string,
      createdAt?: string | null,
      dateCreated: string,
      dateCompleted?: string | null,
      timeOfCall?: string | null,
      timePickedUp?: string | null,
      timePickedUpSenderName?: string | null,
      timeDroppedOff?: string | null,
      timeDroppedOffRecipientName?: string | null,
      timeCancelled?: string | null,
      timeRejected?: string | null,
      timeRiderHome?: string | null,
      pickUpLocationId?: string | null,
      dropOffLocationId?: string | null,
      establishmentLocationId?: string | null,
      riderResponsibility?: string | null,
      priority?: Priority | null,
      status?: TaskStatus | null,
      isRiderUsingOwnVehicle?: number | null,
      archived?: number | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      userCreatedTasksId?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetTaskAssigneeQueryVariables = {
  id: string,
};

export type GetTaskAssigneeQuery = {
  getTaskAssignee?:  {
    __typename: "TaskAssignee",
    id: string,
    tenantId: string,
    role: Role,
    task:  {
      __typename: "Task",
      id: string,
      tenantId: string,
      createdAt?: string | null,
      dateCreated: string,
      dateCompleted?: string | null,
      timeOfCall?: string | null,
      timePickedUp?: string | null,
      timePickedUpSenderName?: string | null,
      timeDroppedOff?: string | null,
      timeDroppedOffRecipientName?: string | null,
      timeCancelled?: string | null,
      timeRejected?: string | null,
      timeRiderHome?: string | null,
      pickUpLocationId?: string | null,
      dropOffLocationId?: string | null,
      establishmentLocationId?: string | null,
      riderResponsibility?: string | null,
      priority?: Priority | null,
      status?: TaskStatus | null,
      isRiderUsingOwnVehicle?: number | null,
      archived?: number | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      userCreatedTasksId?: string | null,
    },
    assignee:  {
      __typename: "User",
      id: string,
      username: string,
      cognitoId: string,
      tenantId: string,
      isPrimaryAdmin?: number | null,
      displayName: string,
      name?: string | null,
      roles: Array< Role >,
      dateOfBirth?: string | null,
      riderResponsibility?: string | null,
      disabled?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    archived?: number | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    userAssignmentsId?: string | null,
    taskAssigneesId?: string | null,
  } | null,
};

export type ListTaskAssigneesQueryVariables = {
  filter?: ModelTaskAssigneeFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListTaskAssigneesQuery = {
  listTaskAssignees?:  {
    __typename: "ModelTaskAssigneeConnection",
    items:  Array< {
      __typename: "TaskAssignee",
      id: string,
      tenantId: string,
      role: Role,
      archived?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      userAssignmentsId?: string | null,
      taskAssigneesId?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncTaskAssigneesQueryVariables = {
  filter?: ModelTaskAssigneeFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncTaskAssigneesQuery = {
  syncTaskAssignees?:  {
    __typename: "ModelTaskAssigneeConnection",
    items:  Array< {
      __typename: "TaskAssignee",
      id: string,
      tenantId: string,
      role: Role,
      archived?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      userAssignmentsId?: string | null,
      taskAssigneesId?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetScheduledTaskQueryVariables = {
  id: string,
};

export type GetScheduledTaskQuery = {
  getScheduledTask?:  {
    __typename: "ScheduledTask",
    id: string,
    tenantId: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      username: string,
      cognitoId: string,
      tenantId: string,
      isPrimaryAdmin?: number | null,
      displayName: string,
      name?: string | null,
      roles: Array< Role >,
      dateOfBirth?: string | null,
      riderResponsibility?: string | null,
      disabled?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    requesterContact?:  {
      __typename: "AddressAndContactDetails",
      name?: string | null,
      telephoneNumber?: string | null,
      mobileNumber?: string | null,
      emailAddress?: string | null,
      ward?: string | null,
      line1?: string | null,
      line2?: string | null,
      line3?: string | null,
      town?: string | null,
      county?: string | null,
      state?: string | null,
      country?: string | null,
      postcode?: string | null,
      what3words?: string | null,
    } | null,
    cronExpression: string,
    pickUpLocationId?: string | null,
    dropOffLocationId?: string | null,
    establishmentLocationId?: string | null,
    pickUpLocation?:  {
      __typename: "Location",
      id: string,
      tenantId: string,
      name?: string | null,
      listed?: number | null,
      ward?: string | null,
      line1?: string | null,
      line2?: string | null,
      line3?: string | null,
      town?: string | null,
      county?: string | null,
      state?: string | null,
      country?: string | null,
      postcode?: string | null,
      what3words?: string | null,
      disabled?: number | null,
      googleMapsPlaceId?: string | null,
      archived?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      userCreatedLocationsId?: string | null,
    } | null,
    dropOffLocation?:  {
      __typename: "Location",
      id: string,
      tenantId: string,
      name?: string | null,
      listed?: number | null,
      ward?: string | null,
      line1?: string | null,
      line2?: string | null,
      line3?: string | null,
      town?: string | null,
      county?: string | null,
      state?: string | null,
      country?: string | null,
      postcode?: string | null,
      what3words?: string | null,
      disabled?: number | null,
      googleMapsPlaceId?: string | null,
      archived?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      userCreatedLocationsId?: string | null,
    } | null,
    establishmentLocation?:  {
      __typename: "Location",
      id: string,
      tenantId: string,
      name?: string | null,
      listed?: number | null,
      ward?: string | null,
      line1?: string | null,
      line2?: string | null,
      line3?: string | null,
      town?: string | null,
      county?: string | null,
      state?: string | null,
      country?: string | null,
      postcode?: string | null,
      what3words?: string | null,
      disabled?: number | null,
      googleMapsPlaceId?: string | null,
      archived?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      userCreatedLocationsId?: string | null,
    } | null,
    priority?: Priority | null,
    deliverables?:  {
      __typename: "ModelDeliverableConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    disabled?: number | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    userCreatedScheduledTasksId?: string | null,
  } | null,
};

export type ListScheduledTasksQueryVariables = {
  filter?: ModelScheduledTaskFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListScheduledTasksQuery = {
  listScheduledTasks?:  {
    __typename: "ModelScheduledTaskConnection",
    items:  Array< {
      __typename: "ScheduledTask",
      id: string,
      tenantId: string,
      cronExpression: string,
      pickUpLocationId?: string | null,
      dropOffLocationId?: string | null,
      establishmentLocationId?: string | null,
      priority?: Priority | null,
      disabled?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      userCreatedScheduledTasksId?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncScheduledTasksQueryVariables = {
  filter?: ModelScheduledTaskFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncScheduledTasksQuery = {
  syncScheduledTasks?:  {
    __typename: "ModelScheduledTaskConnection",
    items:  Array< {
      __typename: "ScheduledTask",
      id: string,
      tenantId: string,
      cronExpression: string,
      pickUpLocationId?: string | null,
      dropOffLocationId?: string | null,
      establishmentLocationId?: string | null,
      priority?: Priority | null,
      disabled?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      userCreatedScheduledTasksId?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetCommentQueryVariables = {
  id: string,
};

export type GetCommentQuery = {
  getComment?:  {
    __typename: "Comment",
    id: string,
    parentId?: string | null,
    owner?: string | null,
    tenantId: string,
    body?: string | null,
    author?:  {
      __typename: "User",
      id: string,
      username: string,
      cognitoId: string,
      tenantId: string,
      isPrimaryAdmin?: number | null,
      displayName: string,
      name?: string | null,
      roles: Array< Role >,
      dateOfBirth?: string | null,
      riderResponsibility?: string | null,
      disabled?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    visibility?: CommentVisibility | null,
    archived?: number | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    userCommentsId?: string | null,
  } | null,
};

export type ListCommentsQueryVariables = {
  filter?: ModelCommentFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListCommentsQuery = {
  listComments?:  {
    __typename: "ModelCommentConnection",
    items:  Array< {
      __typename: "Comment",
      id: string,
      parentId?: string | null,
      owner?: string | null,
      tenantId: string,
      body?: string | null,
      visibility?: CommentVisibility | null,
      archived?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      userCommentsId?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncCommentsQueryVariables = {
  filter?: ModelCommentFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncCommentsQuery = {
  syncComments?:  {
    __typename: "ModelCommentConnection",
    items:  Array< {
      __typename: "Comment",
      id: string,
      parentId?: string | null,
      owner?: string | null,
      tenantId: string,
      body?: string | null,
      visibility?: CommentVisibility | null,
      archived?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      userCommentsId?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetDeliverableTypeQueryVariables = {
  id: string,
};

export type GetDeliverableTypeQuery = {
  getDeliverableType?:  {
    __typename: "DeliverableType",
    id: string,
    label: string,
    tenantId: string,
    icon?: DeliverableTypeIcon | null,
    defaultUnit?: DeliverableUnit | null,
    deliverables?:  {
      __typename: "ModelDeliverableConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    tags?: Array< string | null > | null,
    disabled?: number | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListDeliverableTypesQueryVariables = {
  filter?: ModelDeliverableTypeFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListDeliverableTypesQuery = {
  listDeliverableTypes?:  {
    __typename: "ModelDeliverableTypeConnection",
    items:  Array< {
      __typename: "DeliverableType",
      id: string,
      label: string,
      tenantId: string,
      icon?: DeliverableTypeIcon | null,
      defaultUnit?: DeliverableUnit | null,
      tags?: Array< string | null > | null,
      disabled?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncDeliverableTypesQueryVariables = {
  filter?: ModelDeliverableTypeFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncDeliverableTypesQuery = {
  syncDeliverableTypes?:  {
    __typename: "ModelDeliverableTypeConnection",
    items:  Array< {
      __typename: "DeliverableType",
      id: string,
      label: string,
      tenantId: string,
      icon?: DeliverableTypeIcon | null,
      defaultUnit?: DeliverableUnit | null,
      tags?: Array< string | null > | null,
      disabled?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetDeliverableQueryVariables = {
  id: string,
};

export type GetDeliverableQuery = {
  getDeliverable?:  {
    __typename: "Deliverable",
    id: string,
    tenantId: string,
    deliverableType?:  {
      __typename: "DeliverableType",
      id: string,
      label: string,
      tenantId: string,
      icon?: DeliverableTypeIcon | null,
      defaultUnit?: DeliverableUnit | null,
      tags?: Array< string | null > | null,
      disabled?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    task?:  {
      __typename: "Task",
      id: string,
      tenantId: string,
      createdAt?: string | null,
      dateCreated: string,
      dateCompleted?: string | null,
      timeOfCall?: string | null,
      timePickedUp?: string | null,
      timePickedUpSenderName?: string | null,
      timeDroppedOff?: string | null,
      timeDroppedOffRecipientName?: string | null,
      timeCancelled?: string | null,
      timeRejected?: string | null,
      timeRiderHome?: string | null,
      pickUpLocationId?: string | null,
      dropOffLocationId?: string | null,
      establishmentLocationId?: string | null,
      riderResponsibility?: string | null,
      priority?: Priority | null,
      status?: TaskStatus | null,
      isRiderUsingOwnVehicle?: number | null,
      archived?: number | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      userCreatedTasksId?: string | null,
    } | null,
    scheduledTask?:  {
      __typename: "ScheduledTask",
      id: string,
      tenantId: string,
      cronExpression: string,
      pickUpLocationId?: string | null,
      dropOffLocationId?: string | null,
      establishmentLocationId?: string | null,
      priority?: Priority | null,
      disabled?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      userCreatedScheduledTasksId?: string | null,
    } | null,
    count?: number | null,
    unit?: DeliverableUnit | null,
    orderInGrid?: number | null,
    comments?:  {
      __typename: "ModelCommentConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    archived?: number | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    taskDeliverablesId?: string | null,
    scheduledTaskDeliverablesId?: string | null,
    deliverableTypeDeliverablesId?: string | null,
  } | null,
};

export type ListDeliverablesQueryVariables = {
  filter?: ModelDeliverableFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListDeliverablesQuery = {
  listDeliverables?:  {
    __typename: "ModelDeliverableConnection",
    items:  Array< {
      __typename: "Deliverable",
      id: string,
      tenantId: string,
      count?: number | null,
      unit?: DeliverableUnit | null,
      orderInGrid?: number | null,
      archived?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      taskDeliverablesId?: string | null,
      scheduledTaskDeliverablesId?: string | null,
      deliverableTypeDeliverablesId?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncDeliverablesQueryVariables = {
  filter?: ModelDeliverableFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncDeliverablesQuery = {
  syncDeliverables?:  {
    __typename: "ModelDeliverableConnection",
    items:  Array< {
      __typename: "Deliverable",
      id: string,
      tenantId: string,
      count?: number | null,
      unit?: DeliverableUnit | null,
      orderInGrid?: number | null,
      archived?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      taskDeliverablesId?: string | null,
      scheduledTaskDeliverablesId?: string | null,
      deliverableTypeDeliverablesId?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetRiderResponsibilityQueryVariables = {
  id: string,
};

export type GetRiderResponsibilityQuery = {
  getRiderResponsibility?:  {
    __typename: "RiderResponsibility",
    id: string,
    tenantId: string,
    label: string,
    disabled?: number | null,
    possibleUsers?:  {
      __typename: "ModelPossibleRiderResponsibilitiesConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListRiderResponsibilitiesQueryVariables = {
  filter?: ModelRiderResponsibilityFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListRiderResponsibilitiesQuery = {
  listRiderResponsibilities?:  {
    __typename: "ModelRiderResponsibilityConnection",
    items:  Array< {
      __typename: "RiderResponsibility",
      id: string,
      tenantId: string,
      label: string,
      disabled?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncRiderResponsibilitiesQueryVariables = {
  filter?: ModelRiderResponsibilityFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncRiderResponsibilitiesQuery = {
  syncRiderResponsibilities?:  {
    __typename: "ModelRiderResponsibilityConnection",
    items:  Array< {
      __typename: "RiderResponsibility",
      id: string,
      tenantId: string,
      label: string,
      disabled?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ProfilePictureUploadURLQueryVariables = {
  userId: string,
};

export type ProfilePictureUploadURLQuery = {
  profilePictureUploadURL?: string | null,
};

export type ProfilePictureURLQueryVariables = {
  userId: string,
  width?: number | null,
  height?: number | null,
};

export type ProfilePictureURLQuery = {
  profilePictureURL?: string | null,
};

export type SendUserFeedbackQueryVariables = {
  emailAddress?: string | null,
  body?: string | null,
};

export type SendUserFeedbackQuery = {
  sendUserFeedback?:  {
    __typename: "SendFeedback",
    successState?: boolean | null,
  } | null,
};

export type OnCreateTenantSubscriptionVariables = {
  filter?: ModelSubscriptionTenantFilterInput | null,
};

export type OnCreateTenantSubscription = {
  onCreateTenant?:  {
    __typename: "Tenant",
    id: string,
    name: string,
    referenceIdentifier: string,
    admin:  {
      __typename: "User",
      id: string,
      username: string,
      cognitoId: string,
      tenantId: string,
      isPrimaryAdmin?: number | null,
      displayName: string,
      name?: string | null,
      roles: Array< Role >,
      dateOfBirth?: string | null,
      riderResponsibility?: string | null,
      disabled?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    tenantAdminId: string,
  } | null,
};

export type OnUpdateTenantSubscriptionVariables = {
  filter?: ModelSubscriptionTenantFilterInput | null,
};

export type OnUpdateTenantSubscription = {
  onUpdateTenant?:  {
    __typename: "Tenant",
    id: string,
    name: string,
    referenceIdentifier: string,
    admin:  {
      __typename: "User",
      id: string,
      username: string,
      cognitoId: string,
      tenantId: string,
      isPrimaryAdmin?: number | null,
      displayName: string,
      name?: string | null,
      roles: Array< Role >,
      dateOfBirth?: string | null,
      riderResponsibility?: string | null,
      disabled?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    tenantAdminId: string,
  } | null,
};

export type OnDeleteTenantSubscriptionVariables = {
  filter?: ModelSubscriptionTenantFilterInput | null,
};

export type OnDeleteTenantSubscription = {
  onDeleteTenant?:  {
    __typename: "Tenant",
    id: string,
    name: string,
    referenceIdentifier: string,
    admin:  {
      __typename: "User",
      id: string,
      username: string,
      cognitoId: string,
      tenantId: string,
      isPrimaryAdmin?: number | null,
      displayName: string,
      name?: string | null,
      roles: Array< Role >,
      dateOfBirth?: string | null,
      riderResponsibility?: string | null,
      disabled?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    tenantAdminId: string,
  } | null,
};

export type OnCreateUserSubscriptionVariables = {
  filter?: ModelSubscriptionUserFilterInput | null,
  cognitoId?: string | null,
};

export type OnCreateUserSubscription = {
  onCreateUser?:  {
    __typename: "User",
    id: string,
    username: string,
    cognitoId: string,
    tenantId: string,
    isPrimaryAdmin?: number | null,
    contact?:  {
      __typename: "AddressAndContactDetails",
      name?: string | null,
      telephoneNumber?: string | null,
      mobileNumber?: string | null,
      emailAddress?: string | null,
      ward?: string | null,
      line1?: string | null,
      line2?: string | null,
      line3?: string | null,
      town?: string | null,
      county?: string | null,
      state?: string | null,
      country?: string | null,
      postcode?: string | null,
      what3words?: string | null,
    } | null,
    displayName: string,
    name?: string | null,
    roles: Array< Role >,
    dateOfBirth?: string | null,
    riderResponsibility?: string | null,
    possibleRiderResponsibilities?:  {
      __typename: "ModelPossibleRiderResponsibilitiesConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    profilePicture?:  {
      __typename: "S3Object",
      bucket: string,
      key: string,
      region: string,
    } | null,
    comments?:  {
      __typename: "ModelCommentConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    assignments?:  {
      __typename: "ModelTaskAssigneeConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    vehicleAssignments?:  {
      __typename: "ModelVehicleAssignmentConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdTasks?:  {
      __typename: "ModelTaskConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdLocations?:  {
      __typename: "ModelLocationConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdVehicles?:  {
      __typename: "ModelVehicleConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdScheduledTasks?:  {
      __typename: "ModelScheduledTaskConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    disabled?: number | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateUserSubscriptionVariables = {
  filter?: ModelSubscriptionUserFilterInput | null,
  cognitoId?: string | null,
};

export type OnUpdateUserSubscription = {
  onUpdateUser?:  {
    __typename: "User",
    id: string,
    username: string,
    cognitoId: string,
    tenantId: string,
    isPrimaryAdmin?: number | null,
    contact?:  {
      __typename: "AddressAndContactDetails",
      name?: string | null,
      telephoneNumber?: string | null,
      mobileNumber?: string | null,
      emailAddress?: string | null,
      ward?: string | null,
      line1?: string | null,
      line2?: string | null,
      line3?: string | null,
      town?: string | null,
      county?: string | null,
      state?: string | null,
      country?: string | null,
      postcode?: string | null,
      what3words?: string | null,
    } | null,
    displayName: string,
    name?: string | null,
    roles: Array< Role >,
    dateOfBirth?: string | null,
    riderResponsibility?: string | null,
    possibleRiderResponsibilities?:  {
      __typename: "ModelPossibleRiderResponsibilitiesConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    profilePicture?:  {
      __typename: "S3Object",
      bucket: string,
      key: string,
      region: string,
    } | null,
    comments?:  {
      __typename: "ModelCommentConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    assignments?:  {
      __typename: "ModelTaskAssigneeConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    vehicleAssignments?:  {
      __typename: "ModelVehicleAssignmentConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdTasks?:  {
      __typename: "ModelTaskConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdLocations?:  {
      __typename: "ModelLocationConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdVehicles?:  {
      __typename: "ModelVehicleConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdScheduledTasks?:  {
      __typename: "ModelScheduledTaskConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    disabled?: number | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteUserSubscriptionVariables = {
  filter?: ModelSubscriptionUserFilterInput | null,
  cognitoId?: string | null,
};

export type OnDeleteUserSubscription = {
  onDeleteUser?:  {
    __typename: "User",
    id: string,
    username: string,
    cognitoId: string,
    tenantId: string,
    isPrimaryAdmin?: number | null,
    contact?:  {
      __typename: "AddressAndContactDetails",
      name?: string | null,
      telephoneNumber?: string | null,
      mobileNumber?: string | null,
      emailAddress?: string | null,
      ward?: string | null,
      line1?: string | null,
      line2?: string | null,
      line3?: string | null,
      town?: string | null,
      county?: string | null,
      state?: string | null,
      country?: string | null,
      postcode?: string | null,
      what3words?: string | null,
    } | null,
    displayName: string,
    name?: string | null,
    roles: Array< Role >,
    dateOfBirth?: string | null,
    riderResponsibility?: string | null,
    possibleRiderResponsibilities?:  {
      __typename: "ModelPossibleRiderResponsibilitiesConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    profilePicture?:  {
      __typename: "S3Object",
      bucket: string,
      key: string,
      region: string,
    } | null,
    comments?:  {
      __typename: "ModelCommentConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    assignments?:  {
      __typename: "ModelTaskAssigneeConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    vehicleAssignments?:  {
      __typename: "ModelVehicleAssignmentConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdTasks?:  {
      __typename: "ModelTaskConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdLocations?:  {
      __typename: "ModelLocationConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdVehicles?:  {
      __typename: "ModelVehicleConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdScheduledTasks?:  {
      __typename: "ModelScheduledTaskConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    disabled?: number | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreatePossibleRiderResponsibilitiesSubscriptionVariables = {
  filter?: ModelSubscriptionPossibleRiderResponsibilitiesFilterInput | null,
};

export type OnCreatePossibleRiderResponsibilitiesSubscription = {
  onCreatePossibleRiderResponsibilities?:  {
    __typename: "PossibleRiderResponsibilities",
    id: string,
    tenantId: string,
    user:  {
      __typename: "User",
      id: string,
      username: string,
      cognitoId: string,
      tenantId: string,
      isPrimaryAdmin?: number | null,
      displayName: string,
      name?: string | null,
      roles: Array< Role >,
      dateOfBirth?: string | null,
      riderResponsibility?: string | null,
      disabled?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    riderResponsibility:  {
      __typename: "RiderResponsibility",
      id: string,
      tenantId: string,
      label: string,
      disabled?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    userPossibleRiderResponsibilitiesId?: string | null,
    riderResponsibilityPossibleUsersId?: string | null,
  } | null,
};

export type OnUpdatePossibleRiderResponsibilitiesSubscriptionVariables = {
  filter?: ModelSubscriptionPossibleRiderResponsibilitiesFilterInput | null,
};

export type OnUpdatePossibleRiderResponsibilitiesSubscription = {
  onUpdatePossibleRiderResponsibilities?:  {
    __typename: "PossibleRiderResponsibilities",
    id: string,
    tenantId: string,
    user:  {
      __typename: "User",
      id: string,
      username: string,
      cognitoId: string,
      tenantId: string,
      isPrimaryAdmin?: number | null,
      displayName: string,
      name?: string | null,
      roles: Array< Role >,
      dateOfBirth?: string | null,
      riderResponsibility?: string | null,
      disabled?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    riderResponsibility:  {
      __typename: "RiderResponsibility",
      id: string,
      tenantId: string,
      label: string,
      disabled?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    userPossibleRiderResponsibilitiesId?: string | null,
    riderResponsibilityPossibleUsersId?: string | null,
  } | null,
};

export type OnDeletePossibleRiderResponsibilitiesSubscriptionVariables = {
  filter?: ModelSubscriptionPossibleRiderResponsibilitiesFilterInput | null,
};

export type OnDeletePossibleRiderResponsibilitiesSubscription = {
  onDeletePossibleRiderResponsibilities?:  {
    __typename: "PossibleRiderResponsibilities",
    id: string,
    tenantId: string,
    user:  {
      __typename: "User",
      id: string,
      username: string,
      cognitoId: string,
      tenantId: string,
      isPrimaryAdmin?: number | null,
      displayName: string,
      name?: string | null,
      roles: Array< Role >,
      dateOfBirth?: string | null,
      riderResponsibility?: string | null,
      disabled?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    riderResponsibility:  {
      __typename: "RiderResponsibility",
      id: string,
      tenantId: string,
      label: string,
      disabled?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    userPossibleRiderResponsibilitiesId?: string | null,
    riderResponsibilityPossibleUsersId?: string | null,
  } | null,
};

export type OnCreateVehicleSubscriptionVariables = {
  filter?: ModelSubscriptionVehicleFilterInput | null,
};

export type OnCreateVehicleSubscription = {
  onCreateVehicle?:  {
    __typename: "Vehicle",
    id: string,
    tenantId: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      username: string,
      cognitoId: string,
      tenantId: string,
      isPrimaryAdmin?: number | null,
      displayName: string,
      name?: string | null,
      roles: Array< Role >,
      dateOfBirth?: string | null,
      riderResponsibility?: string | null,
      disabled?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    name?: string | null,
    manufacturer?: string | null,
    model?: string | null,
    dateOfManufacture?: string | null,
    dateOfRegistration?: string | null,
    assignments?:  {
      __typename: "ModelVehicleAssignmentConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    comments?:  {
      __typename: "ModelCommentConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    disabled?: number | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    userCreatedVehiclesId?: string | null,
  } | null,
};

export type OnUpdateVehicleSubscriptionVariables = {
  filter?: ModelSubscriptionVehicleFilterInput | null,
};

export type OnUpdateVehicleSubscription = {
  onUpdateVehicle?:  {
    __typename: "Vehicle",
    id: string,
    tenantId: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      username: string,
      cognitoId: string,
      tenantId: string,
      isPrimaryAdmin?: number | null,
      displayName: string,
      name?: string | null,
      roles: Array< Role >,
      dateOfBirth?: string | null,
      riderResponsibility?: string | null,
      disabled?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    name?: string | null,
    manufacturer?: string | null,
    model?: string | null,
    dateOfManufacture?: string | null,
    dateOfRegistration?: string | null,
    assignments?:  {
      __typename: "ModelVehicleAssignmentConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    comments?:  {
      __typename: "ModelCommentConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    disabled?: number | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    userCreatedVehiclesId?: string | null,
  } | null,
};

export type OnDeleteVehicleSubscriptionVariables = {
  filter?: ModelSubscriptionVehicleFilterInput | null,
};

export type OnDeleteVehicleSubscription = {
  onDeleteVehicle?:  {
    __typename: "Vehicle",
    id: string,
    tenantId: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      username: string,
      cognitoId: string,
      tenantId: string,
      isPrimaryAdmin?: number | null,
      displayName: string,
      name?: string | null,
      roles: Array< Role >,
      dateOfBirth?: string | null,
      riderResponsibility?: string | null,
      disabled?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    name?: string | null,
    manufacturer?: string | null,
    model?: string | null,
    dateOfManufacture?: string | null,
    dateOfRegistration?: string | null,
    assignments?:  {
      __typename: "ModelVehicleAssignmentConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    comments?:  {
      __typename: "ModelCommentConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    disabled?: number | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    userCreatedVehiclesId?: string | null,
  } | null,
};

export type OnCreateVehicleAssignmentSubscriptionVariables = {
  filter?: ModelSubscriptionVehicleAssignmentFilterInput | null,
};

export type OnCreateVehicleAssignmentSubscription = {
  onCreateVehicleAssignment?:  {
    __typename: "VehicleAssignment",
    id: string,
    tenantId: string,
    vehicle:  {
      __typename: "Vehicle",
      id: string,
      tenantId: string,
      name?: string | null,
      manufacturer?: string | null,
      model?: string | null,
      dateOfManufacture?: string | null,
      dateOfRegistration?: string | null,
      disabled?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      userCreatedVehiclesId?: string | null,
    },
    assignee:  {
      __typename: "User",
      id: string,
      username: string,
      cognitoId: string,
      tenantId: string,
      isPrimaryAdmin?: number | null,
      displayName: string,
      name?: string | null,
      roles: Array< Role >,
      dateOfBirth?: string | null,
      riderResponsibility?: string | null,
      disabled?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    userVehicleAssignmentsId?: string | null,
    vehicleAssignmentsId?: string | null,
  } | null,
};

export type OnUpdateVehicleAssignmentSubscriptionVariables = {
  filter?: ModelSubscriptionVehicleAssignmentFilterInput | null,
};

export type OnUpdateVehicleAssignmentSubscription = {
  onUpdateVehicleAssignment?:  {
    __typename: "VehicleAssignment",
    id: string,
    tenantId: string,
    vehicle:  {
      __typename: "Vehicle",
      id: string,
      tenantId: string,
      name?: string | null,
      manufacturer?: string | null,
      model?: string | null,
      dateOfManufacture?: string | null,
      dateOfRegistration?: string | null,
      disabled?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      userCreatedVehiclesId?: string | null,
    },
    assignee:  {
      __typename: "User",
      id: string,
      username: string,
      cognitoId: string,
      tenantId: string,
      isPrimaryAdmin?: number | null,
      displayName: string,
      name?: string | null,
      roles: Array< Role >,
      dateOfBirth?: string | null,
      riderResponsibility?: string | null,
      disabled?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    userVehicleAssignmentsId?: string | null,
    vehicleAssignmentsId?: string | null,
  } | null,
};

export type OnDeleteVehicleAssignmentSubscriptionVariables = {
  filter?: ModelSubscriptionVehicleAssignmentFilterInput | null,
};

export type OnDeleteVehicleAssignmentSubscription = {
  onDeleteVehicleAssignment?:  {
    __typename: "VehicleAssignment",
    id: string,
    tenantId: string,
    vehicle:  {
      __typename: "Vehicle",
      id: string,
      tenantId: string,
      name?: string | null,
      manufacturer?: string | null,
      model?: string | null,
      dateOfManufacture?: string | null,
      dateOfRegistration?: string | null,
      disabled?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      userCreatedVehiclesId?: string | null,
    },
    assignee:  {
      __typename: "User",
      id: string,
      username: string,
      cognitoId: string,
      tenantId: string,
      isPrimaryAdmin?: number | null,
      displayName: string,
      name?: string | null,
      roles: Array< Role >,
      dateOfBirth?: string | null,
      riderResponsibility?: string | null,
      disabled?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    userVehicleAssignmentsId?: string | null,
    vehicleAssignmentsId?: string | null,
  } | null,
};

export type OnCreateLocationSubscriptionVariables = {
  filter?: ModelSubscriptionLocationFilterInput | null,
};

export type OnCreateLocationSubscription = {
  onCreateLocation?:  {
    __typename: "Location",
    id: string,
    tenantId: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      username: string,
      cognitoId: string,
      tenantId: string,
      isPrimaryAdmin?: number | null,
      displayName: string,
      name?: string | null,
      roles: Array< Role >,
      dateOfBirth?: string | null,
      riderResponsibility?: string | null,
      disabled?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    name?: string | null,
    listed?: number | null,
    contact?:  {
      __typename: "AddressAndContactDetails",
      name?: string | null,
      telephoneNumber?: string | null,
      mobileNumber?: string | null,
      emailAddress?: string | null,
      ward?: string | null,
      line1?: string | null,
      line2?: string | null,
      line3?: string | null,
      town?: string | null,
      county?: string | null,
      state?: string | null,
      country?: string | null,
      postcode?: string | null,
      what3words?: string | null,
    } | null,
    ward?: string | null,
    line1?: string | null,
    line2?: string | null,
    line3?: string | null,
    town?: string | null,
    county?: string | null,
    state?: string | null,
    country?: string | null,
    postcode?: string | null,
    what3words?: string | null,
    tasksAsPickUp?:  {
      __typename: "ModelTaskConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    tasksAsDropOff?:  {
      __typename: "ModelTaskConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    taskAsEstablishment?:  {
      __typename: "ModelTaskConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    scheduledTasksAsPickUp?:  {
      __typename: "ModelScheduledTaskConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    scheduledTasksAsDropOff?:  {
      __typename: "ModelScheduledTaskConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    scheduledTasksAsEstablishment?:  {
      __typename: "ModelScheduledTaskConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    comments?:  {
      __typename: "ModelCommentConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    disabled?: number | null,
    googleMapsPlaceId?: string | null,
    archived?: number | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    userCreatedLocationsId?: string | null,
  } | null,
};

export type OnUpdateLocationSubscriptionVariables = {
  filter?: ModelSubscriptionLocationFilterInput | null,
};

export type OnUpdateLocationSubscription = {
  onUpdateLocation?:  {
    __typename: "Location",
    id: string,
    tenantId: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      username: string,
      cognitoId: string,
      tenantId: string,
      isPrimaryAdmin?: number | null,
      displayName: string,
      name?: string | null,
      roles: Array< Role >,
      dateOfBirth?: string | null,
      riderResponsibility?: string | null,
      disabled?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    name?: string | null,
    listed?: number | null,
    contact?:  {
      __typename: "AddressAndContactDetails",
      name?: string | null,
      telephoneNumber?: string | null,
      mobileNumber?: string | null,
      emailAddress?: string | null,
      ward?: string | null,
      line1?: string | null,
      line2?: string | null,
      line3?: string | null,
      town?: string | null,
      county?: string | null,
      state?: string | null,
      country?: string | null,
      postcode?: string | null,
      what3words?: string | null,
    } | null,
    ward?: string | null,
    line1?: string | null,
    line2?: string | null,
    line3?: string | null,
    town?: string | null,
    county?: string | null,
    state?: string | null,
    country?: string | null,
    postcode?: string | null,
    what3words?: string | null,
    tasksAsPickUp?:  {
      __typename: "ModelTaskConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    tasksAsDropOff?:  {
      __typename: "ModelTaskConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    taskAsEstablishment?:  {
      __typename: "ModelTaskConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    scheduledTasksAsPickUp?:  {
      __typename: "ModelScheduledTaskConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    scheduledTasksAsDropOff?:  {
      __typename: "ModelScheduledTaskConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    scheduledTasksAsEstablishment?:  {
      __typename: "ModelScheduledTaskConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    comments?:  {
      __typename: "ModelCommentConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    disabled?: number | null,
    googleMapsPlaceId?: string | null,
    archived?: number | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    userCreatedLocationsId?: string | null,
  } | null,
};

export type OnDeleteLocationSubscriptionVariables = {
  filter?: ModelSubscriptionLocationFilterInput | null,
};

export type OnDeleteLocationSubscription = {
  onDeleteLocation?:  {
    __typename: "Location",
    id: string,
    tenantId: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      username: string,
      cognitoId: string,
      tenantId: string,
      isPrimaryAdmin?: number | null,
      displayName: string,
      name?: string | null,
      roles: Array< Role >,
      dateOfBirth?: string | null,
      riderResponsibility?: string | null,
      disabled?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    name?: string | null,
    listed?: number | null,
    contact?:  {
      __typename: "AddressAndContactDetails",
      name?: string | null,
      telephoneNumber?: string | null,
      mobileNumber?: string | null,
      emailAddress?: string | null,
      ward?: string | null,
      line1?: string | null,
      line2?: string | null,
      line3?: string | null,
      town?: string | null,
      county?: string | null,
      state?: string | null,
      country?: string | null,
      postcode?: string | null,
      what3words?: string | null,
    } | null,
    ward?: string | null,
    line1?: string | null,
    line2?: string | null,
    line3?: string | null,
    town?: string | null,
    county?: string | null,
    state?: string | null,
    country?: string | null,
    postcode?: string | null,
    what3words?: string | null,
    tasksAsPickUp?:  {
      __typename: "ModelTaskConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    tasksAsDropOff?:  {
      __typename: "ModelTaskConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    taskAsEstablishment?:  {
      __typename: "ModelTaskConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    scheduledTasksAsPickUp?:  {
      __typename: "ModelScheduledTaskConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    scheduledTasksAsDropOff?:  {
      __typename: "ModelScheduledTaskConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    scheduledTasksAsEstablishment?:  {
      __typename: "ModelScheduledTaskConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    comments?:  {
      __typename: "ModelCommentConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    disabled?: number | null,
    googleMapsPlaceId?: string | null,
    archived?: number | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    userCreatedLocationsId?: string | null,
  } | null,
};

export type OnCreateTaskSubscriptionVariables = {
  filter?: ModelSubscriptionTaskFilterInput | null,
};

export type OnCreateTaskSubscription = {
  onCreateTask?:  {
    __typename: "Task",
    id: string,
    tenantId: string,
    createdAt?: string | null,
    createdBy?:  {
      __typename: "User",
      id: string,
      username: string,
      cognitoId: string,
      tenantId: string,
      isPrimaryAdmin?: number | null,
      displayName: string,
      name?: string | null,
      roles: Array< Role >,
      dateOfBirth?: string | null,
      riderResponsibility?: string | null,
      disabled?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    dateCreated: string,
    dateCompleted?: string | null,
    timeOfCall?: string | null,
    timePickedUp?: string | null,
    timePickedUpSenderName?: string | null,
    timeDroppedOff?: string | null,
    timeDroppedOffRecipientName?: string | null,
    timeCancelled?: string | null,
    timeRejected?: string | null,
    timeRiderHome?: string | null,
    requesterContact?:  {
      __typename: "AddressAndContactDetails",
      name?: string | null,
      telephoneNumber?: string | null,
      mobileNumber?: string | null,
      emailAddress?: string | null,
      ward?: string | null,
      line1?: string | null,
      line2?: string | null,
      line3?: string | null,
      town?: string | null,
      county?: string | null,
      state?: string | null,
      country?: string | null,
      postcode?: string | null,
      what3words?: string | null,
    } | null,
    pickUpLocationId?: string | null,
    dropOffLocationId?: string | null,
    establishmentLocationId?: string | null,
    pickUpLocation?:  {
      __typename: "Location",
      id: string,
      tenantId: string,
      name?: string | null,
      listed?: number | null,
      ward?: string | null,
      line1?: string | null,
      line2?: string | null,
      line3?: string | null,
      town?: string | null,
      county?: string | null,
      state?: string | null,
      country?: string | null,
      postcode?: string | null,
      what3words?: string | null,
      disabled?: number | null,
      googleMapsPlaceId?: string | null,
      archived?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      userCreatedLocationsId?: string | null,
    } | null,
    dropOffLocation?:  {
      __typename: "Location",
      id: string,
      tenantId: string,
      name?: string | null,
      listed?: number | null,
      ward?: string | null,
      line1?: string | null,
      line2?: string | null,
      line3?: string | null,
      town?: string | null,
      county?: string | null,
      state?: string | null,
      country?: string | null,
      postcode?: string | null,
      what3words?: string | null,
      disabled?: number | null,
      googleMapsPlaceId?: string | null,
      archived?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      userCreatedLocationsId?: string | null,
    } | null,
    establishmentLocation?:  {
      __typename: "Location",
      id: string,
      tenantId: string,
      name?: string | null,
      listed?: number | null,
      ward?: string | null,
      line1?: string | null,
      line2?: string | null,
      line3?: string | null,
      town?: string | null,
      county?: string | null,
      state?: string | null,
      country?: string | null,
      postcode?: string | null,
      what3words?: string | null,
      disabled?: number | null,
      googleMapsPlaceId?: string | null,
      archived?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      userCreatedLocationsId?: string | null,
    } | null,
    riderResponsibility?: string | null,
    assignees?:  {
      __typename: "ModelTaskAssigneeConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    priority?: Priority | null,
    deliverables?:  {
      __typename: "ModelDeliverableConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    comments?:  {
      __typename: "ModelCommentConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    status?: TaskStatus | null,
    isRiderUsingOwnVehicle?: number | null,
    archived?: number | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    userCreatedTasksId?: string | null,
  } | null,
};

export type OnUpdateTaskSubscriptionVariables = {
  filter?: ModelSubscriptionTaskFilterInput | null,
};

export type OnUpdateTaskSubscription = {
  onUpdateTask?:  {
    __typename: "Task",
    id: string,
    tenantId: string,
    createdAt?: string | null,
    createdBy?:  {
      __typename: "User",
      id: string,
      username: string,
      cognitoId: string,
      tenantId: string,
      isPrimaryAdmin?: number | null,
      displayName: string,
      name?: string | null,
      roles: Array< Role >,
      dateOfBirth?: string | null,
      riderResponsibility?: string | null,
      disabled?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    dateCreated: string,
    dateCompleted?: string | null,
    timeOfCall?: string | null,
    timePickedUp?: string | null,
    timePickedUpSenderName?: string | null,
    timeDroppedOff?: string | null,
    timeDroppedOffRecipientName?: string | null,
    timeCancelled?: string | null,
    timeRejected?: string | null,
    timeRiderHome?: string | null,
    requesterContact?:  {
      __typename: "AddressAndContactDetails",
      name?: string | null,
      telephoneNumber?: string | null,
      mobileNumber?: string | null,
      emailAddress?: string | null,
      ward?: string | null,
      line1?: string | null,
      line2?: string | null,
      line3?: string | null,
      town?: string | null,
      county?: string | null,
      state?: string | null,
      country?: string | null,
      postcode?: string | null,
      what3words?: string | null,
    } | null,
    pickUpLocationId?: string | null,
    dropOffLocationId?: string | null,
    establishmentLocationId?: string | null,
    pickUpLocation?:  {
      __typename: "Location",
      id: string,
      tenantId: string,
      name?: string | null,
      listed?: number | null,
      ward?: string | null,
      line1?: string | null,
      line2?: string | null,
      line3?: string | null,
      town?: string | null,
      county?: string | null,
      state?: string | null,
      country?: string | null,
      postcode?: string | null,
      what3words?: string | null,
      disabled?: number | null,
      googleMapsPlaceId?: string | null,
      archived?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      userCreatedLocationsId?: string | null,
    } | null,
    dropOffLocation?:  {
      __typename: "Location",
      id: string,
      tenantId: string,
      name?: string | null,
      listed?: number | null,
      ward?: string | null,
      line1?: string | null,
      line2?: string | null,
      line3?: string | null,
      town?: string | null,
      county?: string | null,
      state?: string | null,
      country?: string | null,
      postcode?: string | null,
      what3words?: string | null,
      disabled?: number | null,
      googleMapsPlaceId?: string | null,
      archived?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      userCreatedLocationsId?: string | null,
    } | null,
    establishmentLocation?:  {
      __typename: "Location",
      id: string,
      tenantId: string,
      name?: string | null,
      listed?: number | null,
      ward?: string | null,
      line1?: string | null,
      line2?: string | null,
      line3?: string | null,
      town?: string | null,
      county?: string | null,
      state?: string | null,
      country?: string | null,
      postcode?: string | null,
      what3words?: string | null,
      disabled?: number | null,
      googleMapsPlaceId?: string | null,
      archived?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      userCreatedLocationsId?: string | null,
    } | null,
    riderResponsibility?: string | null,
    assignees?:  {
      __typename: "ModelTaskAssigneeConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    priority?: Priority | null,
    deliverables?:  {
      __typename: "ModelDeliverableConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    comments?:  {
      __typename: "ModelCommentConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    status?: TaskStatus | null,
    isRiderUsingOwnVehicle?: number | null,
    archived?: number | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    userCreatedTasksId?: string | null,
  } | null,
};

export type OnDeleteTaskSubscriptionVariables = {
  filter?: ModelSubscriptionTaskFilterInput | null,
};

export type OnDeleteTaskSubscription = {
  onDeleteTask?:  {
    __typename: "Task",
    id: string,
    tenantId: string,
    createdAt?: string | null,
    createdBy?:  {
      __typename: "User",
      id: string,
      username: string,
      cognitoId: string,
      tenantId: string,
      isPrimaryAdmin?: number | null,
      displayName: string,
      name?: string | null,
      roles: Array< Role >,
      dateOfBirth?: string | null,
      riderResponsibility?: string | null,
      disabled?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    dateCreated: string,
    dateCompleted?: string | null,
    timeOfCall?: string | null,
    timePickedUp?: string | null,
    timePickedUpSenderName?: string | null,
    timeDroppedOff?: string | null,
    timeDroppedOffRecipientName?: string | null,
    timeCancelled?: string | null,
    timeRejected?: string | null,
    timeRiderHome?: string | null,
    requesterContact?:  {
      __typename: "AddressAndContactDetails",
      name?: string | null,
      telephoneNumber?: string | null,
      mobileNumber?: string | null,
      emailAddress?: string | null,
      ward?: string | null,
      line1?: string | null,
      line2?: string | null,
      line3?: string | null,
      town?: string | null,
      county?: string | null,
      state?: string | null,
      country?: string | null,
      postcode?: string | null,
      what3words?: string | null,
    } | null,
    pickUpLocationId?: string | null,
    dropOffLocationId?: string | null,
    establishmentLocationId?: string | null,
    pickUpLocation?:  {
      __typename: "Location",
      id: string,
      tenantId: string,
      name?: string | null,
      listed?: number | null,
      ward?: string | null,
      line1?: string | null,
      line2?: string | null,
      line3?: string | null,
      town?: string | null,
      county?: string | null,
      state?: string | null,
      country?: string | null,
      postcode?: string | null,
      what3words?: string | null,
      disabled?: number | null,
      googleMapsPlaceId?: string | null,
      archived?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      userCreatedLocationsId?: string | null,
    } | null,
    dropOffLocation?:  {
      __typename: "Location",
      id: string,
      tenantId: string,
      name?: string | null,
      listed?: number | null,
      ward?: string | null,
      line1?: string | null,
      line2?: string | null,
      line3?: string | null,
      town?: string | null,
      county?: string | null,
      state?: string | null,
      country?: string | null,
      postcode?: string | null,
      what3words?: string | null,
      disabled?: number | null,
      googleMapsPlaceId?: string | null,
      archived?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      userCreatedLocationsId?: string | null,
    } | null,
    establishmentLocation?:  {
      __typename: "Location",
      id: string,
      tenantId: string,
      name?: string | null,
      listed?: number | null,
      ward?: string | null,
      line1?: string | null,
      line2?: string | null,
      line3?: string | null,
      town?: string | null,
      county?: string | null,
      state?: string | null,
      country?: string | null,
      postcode?: string | null,
      what3words?: string | null,
      disabled?: number | null,
      googleMapsPlaceId?: string | null,
      archived?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      userCreatedLocationsId?: string | null,
    } | null,
    riderResponsibility?: string | null,
    assignees?:  {
      __typename: "ModelTaskAssigneeConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    priority?: Priority | null,
    deliverables?:  {
      __typename: "ModelDeliverableConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    comments?:  {
      __typename: "ModelCommentConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    status?: TaskStatus | null,
    isRiderUsingOwnVehicle?: number | null,
    archived?: number | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    userCreatedTasksId?: string | null,
  } | null,
};

export type OnCreateTaskAssigneeSubscriptionVariables = {
  filter?: ModelSubscriptionTaskAssigneeFilterInput | null,
};

export type OnCreateTaskAssigneeSubscription = {
  onCreateTaskAssignee?:  {
    __typename: "TaskAssignee",
    id: string,
    tenantId: string,
    role: Role,
    task:  {
      __typename: "Task",
      id: string,
      tenantId: string,
      createdAt?: string | null,
      dateCreated: string,
      dateCompleted?: string | null,
      timeOfCall?: string | null,
      timePickedUp?: string | null,
      timePickedUpSenderName?: string | null,
      timeDroppedOff?: string | null,
      timeDroppedOffRecipientName?: string | null,
      timeCancelled?: string | null,
      timeRejected?: string | null,
      timeRiderHome?: string | null,
      pickUpLocationId?: string | null,
      dropOffLocationId?: string | null,
      establishmentLocationId?: string | null,
      riderResponsibility?: string | null,
      priority?: Priority | null,
      status?: TaskStatus | null,
      isRiderUsingOwnVehicle?: number | null,
      archived?: number | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      userCreatedTasksId?: string | null,
    },
    assignee:  {
      __typename: "User",
      id: string,
      username: string,
      cognitoId: string,
      tenantId: string,
      isPrimaryAdmin?: number | null,
      displayName: string,
      name?: string | null,
      roles: Array< Role >,
      dateOfBirth?: string | null,
      riderResponsibility?: string | null,
      disabled?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    archived?: number | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    userAssignmentsId?: string | null,
    taskAssigneesId?: string | null,
  } | null,
};

export type OnUpdateTaskAssigneeSubscriptionVariables = {
  filter?: ModelSubscriptionTaskAssigneeFilterInput | null,
};

export type OnUpdateTaskAssigneeSubscription = {
  onUpdateTaskAssignee?:  {
    __typename: "TaskAssignee",
    id: string,
    tenantId: string,
    role: Role,
    task:  {
      __typename: "Task",
      id: string,
      tenantId: string,
      createdAt?: string | null,
      dateCreated: string,
      dateCompleted?: string | null,
      timeOfCall?: string | null,
      timePickedUp?: string | null,
      timePickedUpSenderName?: string | null,
      timeDroppedOff?: string | null,
      timeDroppedOffRecipientName?: string | null,
      timeCancelled?: string | null,
      timeRejected?: string | null,
      timeRiderHome?: string | null,
      pickUpLocationId?: string | null,
      dropOffLocationId?: string | null,
      establishmentLocationId?: string | null,
      riderResponsibility?: string | null,
      priority?: Priority | null,
      status?: TaskStatus | null,
      isRiderUsingOwnVehicle?: number | null,
      archived?: number | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      userCreatedTasksId?: string | null,
    },
    assignee:  {
      __typename: "User",
      id: string,
      username: string,
      cognitoId: string,
      tenantId: string,
      isPrimaryAdmin?: number | null,
      displayName: string,
      name?: string | null,
      roles: Array< Role >,
      dateOfBirth?: string | null,
      riderResponsibility?: string | null,
      disabled?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    archived?: number | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    userAssignmentsId?: string | null,
    taskAssigneesId?: string | null,
  } | null,
};

export type OnDeleteTaskAssigneeSubscriptionVariables = {
  filter?: ModelSubscriptionTaskAssigneeFilterInput | null,
};

export type OnDeleteTaskAssigneeSubscription = {
  onDeleteTaskAssignee?:  {
    __typename: "TaskAssignee",
    id: string,
    tenantId: string,
    role: Role,
    task:  {
      __typename: "Task",
      id: string,
      tenantId: string,
      createdAt?: string | null,
      dateCreated: string,
      dateCompleted?: string | null,
      timeOfCall?: string | null,
      timePickedUp?: string | null,
      timePickedUpSenderName?: string | null,
      timeDroppedOff?: string | null,
      timeDroppedOffRecipientName?: string | null,
      timeCancelled?: string | null,
      timeRejected?: string | null,
      timeRiderHome?: string | null,
      pickUpLocationId?: string | null,
      dropOffLocationId?: string | null,
      establishmentLocationId?: string | null,
      riderResponsibility?: string | null,
      priority?: Priority | null,
      status?: TaskStatus | null,
      isRiderUsingOwnVehicle?: number | null,
      archived?: number | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      userCreatedTasksId?: string | null,
    },
    assignee:  {
      __typename: "User",
      id: string,
      username: string,
      cognitoId: string,
      tenantId: string,
      isPrimaryAdmin?: number | null,
      displayName: string,
      name?: string | null,
      roles: Array< Role >,
      dateOfBirth?: string | null,
      riderResponsibility?: string | null,
      disabled?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    archived?: number | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    userAssignmentsId?: string | null,
    taskAssigneesId?: string | null,
  } | null,
};

export type OnCreateScheduledTaskSubscriptionVariables = {
  filter?: ModelSubscriptionScheduledTaskFilterInput | null,
};

export type OnCreateScheduledTaskSubscription = {
  onCreateScheduledTask?:  {
    __typename: "ScheduledTask",
    id: string,
    tenantId: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      username: string,
      cognitoId: string,
      tenantId: string,
      isPrimaryAdmin?: number | null,
      displayName: string,
      name?: string | null,
      roles: Array< Role >,
      dateOfBirth?: string | null,
      riderResponsibility?: string | null,
      disabled?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    requesterContact?:  {
      __typename: "AddressAndContactDetails",
      name?: string | null,
      telephoneNumber?: string | null,
      mobileNumber?: string | null,
      emailAddress?: string | null,
      ward?: string | null,
      line1?: string | null,
      line2?: string | null,
      line3?: string | null,
      town?: string | null,
      county?: string | null,
      state?: string | null,
      country?: string | null,
      postcode?: string | null,
      what3words?: string | null,
    } | null,
    cronExpression: string,
    pickUpLocationId?: string | null,
    dropOffLocationId?: string | null,
    establishmentLocationId?: string | null,
    pickUpLocation?:  {
      __typename: "Location",
      id: string,
      tenantId: string,
      name?: string | null,
      listed?: number | null,
      ward?: string | null,
      line1?: string | null,
      line2?: string | null,
      line3?: string | null,
      town?: string | null,
      county?: string | null,
      state?: string | null,
      country?: string | null,
      postcode?: string | null,
      what3words?: string | null,
      disabled?: number | null,
      googleMapsPlaceId?: string | null,
      archived?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      userCreatedLocationsId?: string | null,
    } | null,
    dropOffLocation?:  {
      __typename: "Location",
      id: string,
      tenantId: string,
      name?: string | null,
      listed?: number | null,
      ward?: string | null,
      line1?: string | null,
      line2?: string | null,
      line3?: string | null,
      town?: string | null,
      county?: string | null,
      state?: string | null,
      country?: string | null,
      postcode?: string | null,
      what3words?: string | null,
      disabled?: number | null,
      googleMapsPlaceId?: string | null,
      archived?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      userCreatedLocationsId?: string | null,
    } | null,
    establishmentLocation?:  {
      __typename: "Location",
      id: string,
      tenantId: string,
      name?: string | null,
      listed?: number | null,
      ward?: string | null,
      line1?: string | null,
      line2?: string | null,
      line3?: string | null,
      town?: string | null,
      county?: string | null,
      state?: string | null,
      country?: string | null,
      postcode?: string | null,
      what3words?: string | null,
      disabled?: number | null,
      googleMapsPlaceId?: string | null,
      archived?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      userCreatedLocationsId?: string | null,
    } | null,
    priority?: Priority | null,
    deliverables?:  {
      __typename: "ModelDeliverableConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    disabled?: number | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    userCreatedScheduledTasksId?: string | null,
  } | null,
};

export type OnUpdateScheduledTaskSubscriptionVariables = {
  filter?: ModelSubscriptionScheduledTaskFilterInput | null,
};

export type OnUpdateScheduledTaskSubscription = {
  onUpdateScheduledTask?:  {
    __typename: "ScheduledTask",
    id: string,
    tenantId: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      username: string,
      cognitoId: string,
      tenantId: string,
      isPrimaryAdmin?: number | null,
      displayName: string,
      name?: string | null,
      roles: Array< Role >,
      dateOfBirth?: string | null,
      riderResponsibility?: string | null,
      disabled?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    requesterContact?:  {
      __typename: "AddressAndContactDetails",
      name?: string | null,
      telephoneNumber?: string | null,
      mobileNumber?: string | null,
      emailAddress?: string | null,
      ward?: string | null,
      line1?: string | null,
      line2?: string | null,
      line3?: string | null,
      town?: string | null,
      county?: string | null,
      state?: string | null,
      country?: string | null,
      postcode?: string | null,
      what3words?: string | null,
    } | null,
    cronExpression: string,
    pickUpLocationId?: string | null,
    dropOffLocationId?: string | null,
    establishmentLocationId?: string | null,
    pickUpLocation?:  {
      __typename: "Location",
      id: string,
      tenantId: string,
      name?: string | null,
      listed?: number | null,
      ward?: string | null,
      line1?: string | null,
      line2?: string | null,
      line3?: string | null,
      town?: string | null,
      county?: string | null,
      state?: string | null,
      country?: string | null,
      postcode?: string | null,
      what3words?: string | null,
      disabled?: number | null,
      googleMapsPlaceId?: string | null,
      archived?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      userCreatedLocationsId?: string | null,
    } | null,
    dropOffLocation?:  {
      __typename: "Location",
      id: string,
      tenantId: string,
      name?: string | null,
      listed?: number | null,
      ward?: string | null,
      line1?: string | null,
      line2?: string | null,
      line3?: string | null,
      town?: string | null,
      county?: string | null,
      state?: string | null,
      country?: string | null,
      postcode?: string | null,
      what3words?: string | null,
      disabled?: number | null,
      googleMapsPlaceId?: string | null,
      archived?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      userCreatedLocationsId?: string | null,
    } | null,
    establishmentLocation?:  {
      __typename: "Location",
      id: string,
      tenantId: string,
      name?: string | null,
      listed?: number | null,
      ward?: string | null,
      line1?: string | null,
      line2?: string | null,
      line3?: string | null,
      town?: string | null,
      county?: string | null,
      state?: string | null,
      country?: string | null,
      postcode?: string | null,
      what3words?: string | null,
      disabled?: number | null,
      googleMapsPlaceId?: string | null,
      archived?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      userCreatedLocationsId?: string | null,
    } | null,
    priority?: Priority | null,
    deliverables?:  {
      __typename: "ModelDeliverableConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    disabled?: number | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    userCreatedScheduledTasksId?: string | null,
  } | null,
};

export type OnDeleteScheduledTaskSubscriptionVariables = {
  filter?: ModelSubscriptionScheduledTaskFilterInput | null,
};

export type OnDeleteScheduledTaskSubscription = {
  onDeleteScheduledTask?:  {
    __typename: "ScheduledTask",
    id: string,
    tenantId: string,
    createdBy?:  {
      __typename: "User",
      id: string,
      username: string,
      cognitoId: string,
      tenantId: string,
      isPrimaryAdmin?: number | null,
      displayName: string,
      name?: string | null,
      roles: Array< Role >,
      dateOfBirth?: string | null,
      riderResponsibility?: string | null,
      disabled?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    requesterContact?:  {
      __typename: "AddressAndContactDetails",
      name?: string | null,
      telephoneNumber?: string | null,
      mobileNumber?: string | null,
      emailAddress?: string | null,
      ward?: string | null,
      line1?: string | null,
      line2?: string | null,
      line3?: string | null,
      town?: string | null,
      county?: string | null,
      state?: string | null,
      country?: string | null,
      postcode?: string | null,
      what3words?: string | null,
    } | null,
    cronExpression: string,
    pickUpLocationId?: string | null,
    dropOffLocationId?: string | null,
    establishmentLocationId?: string | null,
    pickUpLocation?:  {
      __typename: "Location",
      id: string,
      tenantId: string,
      name?: string | null,
      listed?: number | null,
      ward?: string | null,
      line1?: string | null,
      line2?: string | null,
      line3?: string | null,
      town?: string | null,
      county?: string | null,
      state?: string | null,
      country?: string | null,
      postcode?: string | null,
      what3words?: string | null,
      disabled?: number | null,
      googleMapsPlaceId?: string | null,
      archived?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      userCreatedLocationsId?: string | null,
    } | null,
    dropOffLocation?:  {
      __typename: "Location",
      id: string,
      tenantId: string,
      name?: string | null,
      listed?: number | null,
      ward?: string | null,
      line1?: string | null,
      line2?: string | null,
      line3?: string | null,
      town?: string | null,
      county?: string | null,
      state?: string | null,
      country?: string | null,
      postcode?: string | null,
      what3words?: string | null,
      disabled?: number | null,
      googleMapsPlaceId?: string | null,
      archived?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      userCreatedLocationsId?: string | null,
    } | null,
    establishmentLocation?:  {
      __typename: "Location",
      id: string,
      tenantId: string,
      name?: string | null,
      listed?: number | null,
      ward?: string | null,
      line1?: string | null,
      line2?: string | null,
      line3?: string | null,
      town?: string | null,
      county?: string | null,
      state?: string | null,
      country?: string | null,
      postcode?: string | null,
      what3words?: string | null,
      disabled?: number | null,
      googleMapsPlaceId?: string | null,
      archived?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      userCreatedLocationsId?: string | null,
    } | null,
    priority?: Priority | null,
    deliverables?:  {
      __typename: "ModelDeliverableConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    disabled?: number | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    userCreatedScheduledTasksId?: string | null,
  } | null,
};

export type OnCreateCommentSubscriptionVariables = {
  filter?: ModelSubscriptionCommentFilterInput | null,
  owner?: string | null,
};

export type OnCreateCommentSubscription = {
  onCreateComment?:  {
    __typename: "Comment",
    id: string,
    parentId?: string | null,
    owner?: string | null,
    tenantId: string,
    body?: string | null,
    author?:  {
      __typename: "User",
      id: string,
      username: string,
      cognitoId: string,
      tenantId: string,
      isPrimaryAdmin?: number | null,
      displayName: string,
      name?: string | null,
      roles: Array< Role >,
      dateOfBirth?: string | null,
      riderResponsibility?: string | null,
      disabled?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    visibility?: CommentVisibility | null,
    archived?: number | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    userCommentsId?: string | null,
  } | null,
};

export type OnUpdateCommentSubscriptionVariables = {
  filter?: ModelSubscriptionCommentFilterInput | null,
  owner?: string | null,
};

export type OnUpdateCommentSubscription = {
  onUpdateComment?:  {
    __typename: "Comment",
    id: string,
    parentId?: string | null,
    owner?: string | null,
    tenantId: string,
    body?: string | null,
    author?:  {
      __typename: "User",
      id: string,
      username: string,
      cognitoId: string,
      tenantId: string,
      isPrimaryAdmin?: number | null,
      displayName: string,
      name?: string | null,
      roles: Array< Role >,
      dateOfBirth?: string | null,
      riderResponsibility?: string | null,
      disabled?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    visibility?: CommentVisibility | null,
    archived?: number | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    userCommentsId?: string | null,
  } | null,
};

export type OnDeleteCommentSubscriptionVariables = {
  filter?: ModelSubscriptionCommentFilterInput | null,
  owner?: string | null,
};

export type OnDeleteCommentSubscription = {
  onDeleteComment?:  {
    __typename: "Comment",
    id: string,
    parentId?: string | null,
    owner?: string | null,
    tenantId: string,
    body?: string | null,
    author?:  {
      __typename: "User",
      id: string,
      username: string,
      cognitoId: string,
      tenantId: string,
      isPrimaryAdmin?: number | null,
      displayName: string,
      name?: string | null,
      roles: Array< Role >,
      dateOfBirth?: string | null,
      riderResponsibility?: string | null,
      disabled?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    visibility?: CommentVisibility | null,
    archived?: number | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    userCommentsId?: string | null,
  } | null,
};

export type OnCreateDeliverableTypeSubscriptionVariables = {
  filter?: ModelSubscriptionDeliverableTypeFilterInput | null,
};

export type OnCreateDeliverableTypeSubscription = {
  onCreateDeliverableType?:  {
    __typename: "DeliverableType",
    id: string,
    label: string,
    tenantId: string,
    icon?: DeliverableTypeIcon | null,
    defaultUnit?: DeliverableUnit | null,
    deliverables?:  {
      __typename: "ModelDeliverableConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    tags?: Array< string | null > | null,
    disabled?: number | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateDeliverableTypeSubscriptionVariables = {
  filter?: ModelSubscriptionDeliverableTypeFilterInput | null,
};

export type OnUpdateDeliverableTypeSubscription = {
  onUpdateDeliverableType?:  {
    __typename: "DeliverableType",
    id: string,
    label: string,
    tenantId: string,
    icon?: DeliverableTypeIcon | null,
    defaultUnit?: DeliverableUnit | null,
    deliverables?:  {
      __typename: "ModelDeliverableConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    tags?: Array< string | null > | null,
    disabled?: number | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteDeliverableTypeSubscriptionVariables = {
  filter?: ModelSubscriptionDeliverableTypeFilterInput | null,
};

export type OnDeleteDeliverableTypeSubscription = {
  onDeleteDeliverableType?:  {
    __typename: "DeliverableType",
    id: string,
    label: string,
    tenantId: string,
    icon?: DeliverableTypeIcon | null,
    defaultUnit?: DeliverableUnit | null,
    deliverables?:  {
      __typename: "ModelDeliverableConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    tags?: Array< string | null > | null,
    disabled?: number | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateDeliverableSubscriptionVariables = {
  filter?: ModelSubscriptionDeliverableFilterInput | null,
};

export type OnCreateDeliverableSubscription = {
  onCreateDeliverable?:  {
    __typename: "Deliverable",
    id: string,
    tenantId: string,
    deliverableType?:  {
      __typename: "DeliverableType",
      id: string,
      label: string,
      tenantId: string,
      icon?: DeliverableTypeIcon | null,
      defaultUnit?: DeliverableUnit | null,
      tags?: Array< string | null > | null,
      disabled?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    task?:  {
      __typename: "Task",
      id: string,
      tenantId: string,
      createdAt?: string | null,
      dateCreated: string,
      dateCompleted?: string | null,
      timeOfCall?: string | null,
      timePickedUp?: string | null,
      timePickedUpSenderName?: string | null,
      timeDroppedOff?: string | null,
      timeDroppedOffRecipientName?: string | null,
      timeCancelled?: string | null,
      timeRejected?: string | null,
      timeRiderHome?: string | null,
      pickUpLocationId?: string | null,
      dropOffLocationId?: string | null,
      establishmentLocationId?: string | null,
      riderResponsibility?: string | null,
      priority?: Priority | null,
      status?: TaskStatus | null,
      isRiderUsingOwnVehicle?: number | null,
      archived?: number | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      userCreatedTasksId?: string | null,
    } | null,
    scheduledTask?:  {
      __typename: "ScheduledTask",
      id: string,
      tenantId: string,
      cronExpression: string,
      pickUpLocationId?: string | null,
      dropOffLocationId?: string | null,
      establishmentLocationId?: string | null,
      priority?: Priority | null,
      disabled?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      userCreatedScheduledTasksId?: string | null,
    } | null,
    count?: number | null,
    unit?: DeliverableUnit | null,
    orderInGrid?: number | null,
    comments?:  {
      __typename: "ModelCommentConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    archived?: number | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    taskDeliverablesId?: string | null,
    scheduledTaskDeliverablesId?: string | null,
    deliverableTypeDeliverablesId?: string | null,
  } | null,
};

export type OnUpdateDeliverableSubscriptionVariables = {
  filter?: ModelSubscriptionDeliverableFilterInput | null,
};

export type OnUpdateDeliverableSubscription = {
  onUpdateDeliverable?:  {
    __typename: "Deliverable",
    id: string,
    tenantId: string,
    deliverableType?:  {
      __typename: "DeliverableType",
      id: string,
      label: string,
      tenantId: string,
      icon?: DeliverableTypeIcon | null,
      defaultUnit?: DeliverableUnit | null,
      tags?: Array< string | null > | null,
      disabled?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    task?:  {
      __typename: "Task",
      id: string,
      tenantId: string,
      createdAt?: string | null,
      dateCreated: string,
      dateCompleted?: string | null,
      timeOfCall?: string | null,
      timePickedUp?: string | null,
      timePickedUpSenderName?: string | null,
      timeDroppedOff?: string | null,
      timeDroppedOffRecipientName?: string | null,
      timeCancelled?: string | null,
      timeRejected?: string | null,
      timeRiderHome?: string | null,
      pickUpLocationId?: string | null,
      dropOffLocationId?: string | null,
      establishmentLocationId?: string | null,
      riderResponsibility?: string | null,
      priority?: Priority | null,
      status?: TaskStatus | null,
      isRiderUsingOwnVehicle?: number | null,
      archived?: number | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      userCreatedTasksId?: string | null,
    } | null,
    scheduledTask?:  {
      __typename: "ScheduledTask",
      id: string,
      tenantId: string,
      cronExpression: string,
      pickUpLocationId?: string | null,
      dropOffLocationId?: string | null,
      establishmentLocationId?: string | null,
      priority?: Priority | null,
      disabled?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      userCreatedScheduledTasksId?: string | null,
    } | null,
    count?: number | null,
    unit?: DeliverableUnit | null,
    orderInGrid?: number | null,
    comments?:  {
      __typename: "ModelCommentConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    archived?: number | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    taskDeliverablesId?: string | null,
    scheduledTaskDeliverablesId?: string | null,
    deliverableTypeDeliverablesId?: string | null,
  } | null,
};

export type OnDeleteDeliverableSubscriptionVariables = {
  filter?: ModelSubscriptionDeliverableFilterInput | null,
};

export type OnDeleteDeliverableSubscription = {
  onDeleteDeliverable?:  {
    __typename: "Deliverable",
    id: string,
    tenantId: string,
    deliverableType?:  {
      __typename: "DeliverableType",
      id: string,
      label: string,
      tenantId: string,
      icon?: DeliverableTypeIcon | null,
      defaultUnit?: DeliverableUnit | null,
      tags?: Array< string | null > | null,
      disabled?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    task?:  {
      __typename: "Task",
      id: string,
      tenantId: string,
      createdAt?: string | null,
      dateCreated: string,
      dateCompleted?: string | null,
      timeOfCall?: string | null,
      timePickedUp?: string | null,
      timePickedUpSenderName?: string | null,
      timeDroppedOff?: string | null,
      timeDroppedOffRecipientName?: string | null,
      timeCancelled?: string | null,
      timeRejected?: string | null,
      timeRiderHome?: string | null,
      pickUpLocationId?: string | null,
      dropOffLocationId?: string | null,
      establishmentLocationId?: string | null,
      riderResponsibility?: string | null,
      priority?: Priority | null,
      status?: TaskStatus | null,
      isRiderUsingOwnVehicle?: number | null,
      archived?: number | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      userCreatedTasksId?: string | null,
    } | null,
    scheduledTask?:  {
      __typename: "ScheduledTask",
      id: string,
      tenantId: string,
      cronExpression: string,
      pickUpLocationId?: string | null,
      dropOffLocationId?: string | null,
      establishmentLocationId?: string | null,
      priority?: Priority | null,
      disabled?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      userCreatedScheduledTasksId?: string | null,
    } | null,
    count?: number | null,
    unit?: DeliverableUnit | null,
    orderInGrid?: number | null,
    comments?:  {
      __typename: "ModelCommentConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    archived?: number | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    taskDeliverablesId?: string | null,
    scheduledTaskDeliverablesId?: string | null,
    deliverableTypeDeliverablesId?: string | null,
  } | null,
};

export type OnCreateRiderResponsibilitySubscriptionVariables = {
  filter?: ModelSubscriptionRiderResponsibilityFilterInput | null,
};

export type OnCreateRiderResponsibilitySubscription = {
  onCreateRiderResponsibility?:  {
    __typename: "RiderResponsibility",
    id: string,
    tenantId: string,
    label: string,
    disabled?: number | null,
    possibleUsers?:  {
      __typename: "ModelPossibleRiderResponsibilitiesConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateRiderResponsibilitySubscriptionVariables = {
  filter?: ModelSubscriptionRiderResponsibilityFilterInput | null,
};

export type OnUpdateRiderResponsibilitySubscription = {
  onUpdateRiderResponsibility?:  {
    __typename: "RiderResponsibility",
    id: string,
    tenantId: string,
    label: string,
    disabled?: number | null,
    possibleUsers?:  {
      __typename: "ModelPossibleRiderResponsibilitiesConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteRiderResponsibilitySubscriptionVariables = {
  filter?: ModelSubscriptionRiderResponsibilityFilterInput | null,
};

export type OnDeleteRiderResponsibilitySubscription = {
  onDeleteRiderResponsibility?:  {
    __typename: "RiderResponsibility",
    id: string,
    tenantId: string,
    label: string,
    disabled?: number | null,
    possibleUsers?:  {
      __typename: "ModelPossibleRiderResponsibilitiesConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};
